import React, {useEffect, useState} from "react";
import Alert from "./Alert";
import "./Support.scss";
import {Modal} from "bootstrap";

export default function Support() {
    const [errorMsg, setErrorMsg] = useState("")
    const [errorTitle, setErrorTitle] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const preForm = () => {
        if (window.localStorage['user'] && window.localStorage['user'].length > 2) {
            let user = JSON.parse(window.localStorage['user']);
            setName(user.clName)
            setEmail(user.person.email)
            setPhone(user.person.phoneMob)
            return true
        }
        return false
    }

    useEffect(() => {
        if (!preForm()) {
            setTimeout(()=>{
                preForm()
            }, 1000)
        }
    }, [])

    function send() {
        const req = {name, email, phone, message}
        fetch("/api/v1/tools/support", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            redirect: "error"
        })

            .then(r => {
                if (r.status === 204) {
                    Modal.getInstance(document.getElementById('support_message')).hide()
                    let divModalOk = document.getElementById('support_result')
                    let modalOk = new Modal(divModalOk);
                    modalOk.show()
                    return
                }
                return r.json();
            })
            .then(r => {
                if(!r){
                    return;
                }
                    if (r.title) {
                        setErrorTitle(r.title)
                        if (r.detail) {
                            setErrorMsg(r.detail.split("\n"))
                            setTimeout(() => setErrorMsg(""), 10000)
                        }
                        return
                    }
                    if (r.error) {
                        setErrorTitle(r.error)
                        let m = r.errors.map(e => e.defaultMessage)//.map(m=>`<p>${m}</p>`)
                        setErrorMsg(m)
                        setTimeout(() => setErrorMsg(""), 10000)
                        return;
                    }
                    setErrorMsg(r)
                    setTimeout(() => setErrorMsg(""), 10000)
                }
            )
            .catch(err => {
                console.log("error 1:", err)
                setErrorMsg(err)
                setTimeout(() => setErrorMsg(""), 10000)
            })
    }

    return (
        <>
            <div className="modal fade support_form" id="support_message" tabIndex="-1"
                 aria-labelledby="support_message_title"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title fw-bold" id="support_message_title">Задайте питання</h5>
                        </div>
                        <div className="modal-body d-flex justify-content-center">
                            <div className="w-75 ">
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="user_name"
                                           placeholder="ім'я" value={name}
                                           onChange={event => setName(event.target.value)}
                                    />
                                </div>

                                <div className="mb-3">
                                    <input type="email" className="form-control" id="user_email"
                                           placeholder="Email" value={email}
                                           onChange={event => setEmail(event.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="user_phone"
                                           placeholder="+38xxx xxxxxxx"
                                           value={phone} onChange={event => setPhone(event.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control" id="user_message"
                                              placeholder="Питання" rows="4" value={message}
                                              onChange={event => setMessage(event.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center text-center">
                            <button id="support_form" type="button" className="btn btn-danger"
                                    onClick={send}>Надіслати
                            </button>
                            <p>Ми відповімо вам впродовж 24 годин або в найближчий робочий день, якщо сьогодні
                                вихідний</p>
                            {errorMsg &&
                                <Alert header={errorTitle} type={"danger"}>
                                    {!errorMsg.map && errorMsg}
                                    {errorMsg.map && <>
                                        {errorMsg.map((m, i) => (<p key={i}>{m}</p>))}</>
                                    }
                                </Alert>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="support_result" tabIndex="-1" aria-labelledby="support_resultLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="support_resultLabel">Запит</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <strong>Дякуємо за повідомлення!</strong>
                            <p>Ми відповімо вам найближчим часом.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}