import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Header from "../../header/Header";
import Alert from "../../common/Alert";
import CommonInfo from "./CommonInfo";
import Participants from "./Participants";
import Payments from "./Payments";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function Contract() {
    const isBeta = process.env.REACT_APP_IS_BETA || process.env.NODE_ENV === "development"
    const TAB_COMMON = 'common'
    const TAB_PART = 'part'
    const TAB_PAYMENTS = 'payments'
    const TAB_BONUS = 'bonus'
    const TAB_AMOUNTS = 'amounts'
    const [tab, setTab] = useState(TAB_COMMON)

    let params = useParams();
    let contractId = params.contractId

    const [errorMsg, setErrorMsg] = useState("")
    const [contract, setContract] = useState(null)
    const [user, setUser] = useState({"clName": "", "person": {"email": "", "taxID": ""}})

    useEffect(() => {
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contractId}`, {redirect: "error"},
            c => setContract(c),
            reason => setErrorMsg(reason))
    }, [contractId])

    useEffect(() => {
        if(window.localStorage['user'] !== "{}"){
            setUser(JSON.parse(window.localStorage['user']));
            return
        }
        setTimeout(() => {
            if(window.localStorage['user'] !== "{}")
                setUser(JSON.parse(window.localStorage['user']));
        }, 1000);
    }, [])

    return (
        <>
            <div className="row mx-2">
                <div className="col-12 p-2 page-header">
                    <Header showConsultant={false}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-12 p-3 px-5 page-block-min">
                    <nav>
                        <div className="nav nav-tabs" role="tablist">
                            <button type="button" role="tab" onClick={() => setTab(TAB_COMMON)}
                                    className={"nav-link " + (tab === TAB_COMMON ? "active" : "")}>Загальне
                            </button>
                            <button type="button" role="tab" onClick={() => setTab(TAB_PART)}
                                    className={"nav-link " + (tab === TAB_PART ? "active" : "")}>Учасники
                            </button>
                            <button type="button" role="tab" onClick={() => setTab(TAB_PAYMENTS)}
                                    className={"nav-link " + (tab === TAB_PAYMENTS ? "active" : "")}>Платежі
                            </button>
                            {isBeta && <>
                                <button type="button" role="tab" onClick={() => setTab(TAB_BONUS)}
                                        className={"nav-link " + (tab === TAB_BONUS ? "active" : "")}>Бонуси
                                </button>
                                <button type="button" role="tab" onClick={() => setTab(TAB_AMOUNTS)}
                                        className={"nav-link " + (tab === TAB_AMOUNTS ? "active" : "")}>Викупні суми
                                </button>
                            </>}
                        </div>
                    </nav>
                    {contract && contract.error &&
                        <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                            <p>{contract.error}</p>
                            {contract.message && <p>{contract.message}</p>}
                        </Alert>
                    }
                    {errorMsg &&
                        <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                            {errorMsg}
                        </Alert>
                    }
                    {contract && !contract.error &&
                        <div className="tab-content">
                            <div className={"tab-pane fade my-3 " + (tab === TAB_COMMON ? "show active" : "")}>
                                {!contract && <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-success " role="status">
                                        <span className="visually-hidden">Завантаження...</span>
                                    </div>
                                </div>}
                                {contract && <CommonInfo contract={contract} user={user}/>}
                            </div>
                            <div className={"tab-pane fade my-3 " + (tab === TAB_PART ? "show active" : "")}>
                                {!contract && <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-success " role="status">
                                        <span className="visually-hidden">Завантаження...</span>
                                    </div>
                                </div>}
                                {contract && <Participants contract={contract}/>}
                            </div>
                            <div className={"tab-pane fade my-3 " + (tab === TAB_PAYMENTS ? "show active" : "")}>
                                {!contract && <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-success " role="status">
                                        <span className="visually-hidden">Завантаження...</span>
                                    </div>
                                </div>}
                                {contract && <Payments contract={contract} user={user}/>}
                            </div>
                            {isBeta && <>
                                <div className={"tab-pane fade my-3 " + (tab === TAB_BONUS ? "show active" : "")}>
                                    <h4>Бонуси</h4>
                                    Функціонал в розробці
                                </div>
                                <div className={"tab-pane fade my-3 " + (tab === TAB_AMOUNTS ? "show active" : "")}>
                                    <h4>Викупні суми</h4>
                                    Функціонал в розробці
                                </div>
                            </>}
                        </div>
                    }
                </div>
            </div>
        </>
    )
}