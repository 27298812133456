import React, {useEffect, useState} from "react";
import "./cell.scss"
import {Link} from "react-router-dom";

export default function CellYear({month, contract, inpays}) {
    const [user, setUser] =useState({"clName": "", "person": {"email": ""}})
    useEffect(()=>{
        setUser(JSON.parse(window.localStorage['user']));
    },[])

    if (month === undefined || inpays === undefined) {
        return (<td></td>)
    }
    const statusToClass = (status)=>{
        switch (status) {
            case "PAYED":
                return "table-success";
            // return "table-debt";
            case "DEBT":
                return "table-debt";
            case "FUTURE":
                return "table-future";
            default:
                return "";
        }
    }

    const inps = inpays
        .filter((p)=> month === getMonth(p.periodDate))
        .filter((p)=> !(p.oneTimePaymentDate) || (p.oneTimePaymentDate && getYear(p.periodDate) === getYear(p.oneTimePaymentDate)))

    if(inps.length === 0) {
        return (<td></td>)
    }
    const inp = inps[0]

    let w = "";
    switch (inp.status){
        case "PAYED":
            w="Сплачено"
            break
        // return "table-debt";
        case "DEBT":
            w="Сплатити"
            break
        case "FUTURE":
            w="Сплатити"
            break
        default:
            w=""
    }

    const tt = inp.status === "FUTURE" ? `Сплатити до ${inp.periodDate}` : "";
    const status = inp.status
    const c = contract
    const text =  inp.amountContract + " " + contract.currency
    const amount = inp.amountContract
    const cls = statusToClass(status)
    return (
        <td className={cls} data-bs-toggle={(tt ? "tooltip" : "")} data-bs-placement="top" title={tt}>
            {status === "PAYED" &&
                <Link to={`/contracts/${contract.id}`} className={"text-black text-decoration-none"}>{w}<br/>{text}</Link>
            }
            {status === "DEBT" &&
                <Link to={`/contracts/${contract.id}`} className={"text-white text-decoration-none"}>{w}<br/>{text}</Link>
            }
            {status === "FUTURE" &&
                <a href={`https://gli.com.ua/pay/?amount=${amount*100}&numContract=${c.id}&crc=${c.currency}&email=${user.person.email}&fio=${user.clName}&contract_date=${c.start}&inn=${user.person.taxID}`} className={"text-white text-decoration-none"} target="_blank" rel="noreferrer">{w}<br/>{text}</a>
            }

        </td>
    )
}

function getMonth(date) {
    return parseInt(date.split("-")[1]);
}

function getYear(date){
    return parseInt(date.split("-")[0]);
}