import React, {useEffect, useState} from "react";
import './App.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Menu from "./components/nav/Menu";
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import {solid, brands} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Main from "./components/bl/main/Main";
import Profile from "./components/bl/profile/Profile";
import Contracts from "./components/bl/contracts/Contracts";
import Schedule from "./components/bl/schedule/Schedule";
import Payments from "./components/bl/payments/Payments";
import Docs from "./components/bl/docs/Docs";
import Alerts from "./components/bl/alerts/Alerts";
import Contract from "./components/bl/contracts/Contract";
import Support from "./components/common/Support";
import Users from "./components/bl/admin/users/Users";
import Clients from "./components/bl/admin/clients/Clients";
import SupportControl from "./components/bl/admin/support/SupportControl";
import Req4ChangeControl from "./components/bl/admin/req4change/Req4ChangeControl";
import IndexingControl from "./components/bl/admin/indexing/IndexingControl";
import InsuredEvents from "./components/bl/admin/insured_events/InsuredEvents";
import WrapperFetch from "./srv_tools/WrapperFetch";

export const UpdateCounterContext = React.createContext()
let isAdmin = false;

function App() {
    const isBeta = process.env.REACT_APP_IS_BETA || process.env.NODE_ENV === "development"
    const [needUpdate, setNeedUpdate] = useState(true)
    const [collapse, setCollapse] = useState(window.localStorage.getItem("collapse") === "1")
    const [tbBalance, setTbBalance] = useState(-10.0)
    useEffect(() => {
        if (collapse) {
            window.localStorage.setItem("collapse", "1")
        } else {
            window.localStorage.setItem("collapse", null)
        }
    }, [collapse])
    // useEffect(()=>{
    //     if(window.localStorage.getItem("collapse") === "1" && !collapse) {
    //         setCollapse(true)
    //     }
    // }, [])
    const menu = [
        {
            id: 1,
            name: "Головна",
            to: "/",
            icon: solid("house")
        },
        {
            id: 2,
            name: "Мій профіль",
            to: "/profile",
            icon: solid("address-card")
        },
        {
            id: 3,
            name: "Мої договори",
            to: "/contracts",
            icon: solid("file-contract")
        },
        {
            id: 4,
            name: "Графік платежів",
            to: "/schedule",
            icon: solid("calendar-days")
        },
        {
            id: 5,
            name: "Страхові виплати",
            to: "/insurance-payment",
            icon: solid("file-invoice-dollar")
        }
    ]
    if (isBeta) {
        menu.push({
            id: 6,
            name: "Документи",
            to: "/docs",
            icon: solid("file-contract")
        })
        menu.push({
            id: 7,
            name: "Повідомлення",
            to: "/alerts",
            icon: solid("bell")
        })
    }
    const adminMenu = [{
        id: 102,
        name: "Користувачі",
        to: "/users",
        icon: solid("users-gear")
    },
        {
            id: 103,
            name: "Клієнти",
            to: "/clients",
            icon: solid("users")
        },
        {
            id: 105,
            name: "Запити змін профіля",
            to: "/req4change",
            icon: solid("person-circle-question"),
            counter: "/admin/api/v1/tools/req4change/count_new"
        },
        {
            id: 106,
            name: "Запити по індексації",
            to: "/indexing",
            icon: solid("person-arrow-up-from-line"),
            counter: "/admin/api/v1/tools/indexing_requests/count_new"
        },
        {
            id: 107,
            name: "Страхові виплати",
            to: "/adm-insured-payment",
            icon: solid("file-invoice-dollar"),
            counter: "/admin/api/v1/tools/insured_events/count_new"
        },
        {
            id: 104,
            name: "Підтримка",
            to: "/support",
            icon: solid("hands-helping"),
            counter: "/admin/api/v1/tools/support_message/count_new"
        },
    ]

    const [counts, setCounts] = useState({})

    const counter = (to = true) => {
        console.log("to", to)
        let wasSetTo = false
        adminMenu
            .filter(item => item.counter)
            .forEach((item) => {
                fetch(item.counter, {redirect: "error"})
                    .then(r => r.json())
                    .then(d => {
                        setCounts(prevState => {
                            prevState[item.id] = d.count;
                            return {...prevState}
                        })
                        if (to && d.status === undefined && !wasSetTo) {
                            wasSetTo = true
                            setTimeout(() => {
                                counter()
                            }, 90000)
                        }
                    })
            })
    }

    useEffect(() => {
        WrapperFetch.getInstance().enqueue('/api/v1/roles', {redirect: "error"},
            data => {
                data._embedded.strings
                    .map((r) => r.split('_')[1])
                    .filter(r => r === 'ADMIN')
                    .forEach(() => isAdmin = true)
                if (isAdmin) {
                    counter()
                }
            },
            () => {
            }
        )
        setTimeout(() =>
            fetch('/api/v1/build_version', {redirect: "error"})
                .then(r => r.text())
                .then(res => {
                    let uiVersion = process.env.REACT_APP_BUILD
                    console.log("UI", uiVersion, " Service", res)
                    if (parseInt(res) > parseInt(uiVersion)) {
                        window.location.reload(true);
                    }
                }), 1500)
    }, [])

    useEffect(() => {
        if (isAdmin) counter(false)
    }, [needUpdate])

    useEffect(() => {
        if (isAdmin) {
            getBalance()
        }
    }, [isAdmin])

    const getBalance = () => {
        if (isAdmin) {
            fetch('/admin/api/v1/smsBalance', {redirect: "error"})
                .then(r => r.json())
                .then(d => {
                    setTbBalance(d.balance)
                })
            setTimeout(() => {
                getBalance()
            }, 90000)
        }
    }


    return (
        <BrowserRouter>
            <UpdateCounterContext.Provider value={needUpdate}>
                <div className={"d-block d-lg-none"}>
                    {/*Mob menu*/}
                    <nav className="navbar navbar-expand-lg bg-mcolor navbar-dark">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="https://gli.com.ua/" target="_blank"><img
                                className={"logo " + (collapse ? " w-75 mt-2" : "")} src="/img/emblema.png" alt="Gli"/></a>

                            <h6 className={"text-center text-white"}>Зв'язатись з нами</h6>

                            <div className={"text-center mb-2"}>
                                <a href="viber://chat/?number=%2B380502223281&text=Привіт! Мені потрібна допомога"
                                   className={"text-white align-items-center me-4"} target={"_blank"}
                                   rel="noreferrer"><FontAwesomeIcon
                                    icon={brands("viber")} size={"3x"}/></a>
                                <a data-bs-toggle="modal" data-bs-target="#support_message"
                                   className={"text-white align-items-center me-4"}
                                   style={{verticalAlign: "unset"}}>
                                    <FontAwesomeIcon icon={solid("envelope")} size={"3x"}/>
                                </a>
                                <a href="http://m.me/greenwoodlifeinsurance"
                                   className={"text-white align-items-center me-4"} target={"_blank"}
                                   rel="noreferrer"><FontAwesomeIcon
                                    icon={brands("facebook-messenger")} size={"3x"}/></a>
                                <a href="http://t.me/+380502223281"
                                   className={"text-white align-items-center " + (collapse ? " mx-2" : "")}
                                   target="_blank"><FontAwesomeIcon
                                    icon={brands("telegram")} size={"3x"}/></a>
                            </div>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon "></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <Menu menu={menu} adm={adminMenu} collapse={collapse} mob={true} counts={counts}
                                      tbBal={tbBalance}/>
                            </div>

                        </div>
                    </nav>

                </div>
                <div className={"row row-cols-auto gx-0"}>
                    <div className="d-none d-lg-flex sticky-top"
                         style={{height: "100vh", width: collapse ? "5%" : "19%", minWidth: "69px"}}>
                        <div
                            className={"d-flex flex-column flex-shrink-0 text-white bg-mcolor" + (collapse ? "" : " p-3")}
                            style={{width: "100%"}}>
                            <div className={"text-center"}>
                                <a href="https://gli.com.ua/" className={"align-items-center mb-3 mb-md-0 me-md-auto"}
                                   target="_blank">
                                    <img className={"logo " + (collapse ? " w-75 mt-2" : "")} src="/img/emblema.png"
                                         alt="Gli"/>
                                </a>
                            </div>

                            <hr/>
                            <Menu menu={menu} adm={adminMenu} collapse={collapse} counts={counts} tbBal={tbBalance}/>

                            <hr/>
                            <div className="position-relative " style={{top: "-100px"}}>
                                <button
                                    className={"btn-cntrl-collapse position-absolute" + (collapse ? " btn-cntrl-collapsed" : "")}
                                    onClick={() => setCollapse((prevState => !prevState))}>
                                    {collapse && <FontAwesomeIcon icon={solid("angles-right")} size={"3x"}/>}
                                    {!collapse && <FontAwesomeIcon icon={solid("angles-left")} size={"3x"}/>}
                                </button>
                            </div>
                            {!collapse &&
                                <h6 className={"text-center"}>Зв'язатись з нами</h6>
                            }
                            <div className={"text-center" + (collapse ? " mb-2" : "")}>
                                <a href="viber://chat/?number=%2B380502223281&text=Привіт! Мені потрібна допомога"
                                   className={"text-white align-items-center" + (collapse ? " mx-2" : " me-4")}
                                   target="_blank"><FontAwesomeIcon
                                    icon={brands("viber")} size={"2x"}/></a>
                                <a data-bs-toggle="modal" data-bs-target="#support_message"
                                   className={"text-white align-items-center" + (collapse ? " mx-2" : " me-4")}
                                   style={{verticalAlign: "unset"}}>
                                    <FontAwesomeIcon icon={solid("envelope")} size={"2x"}/>
                                </a>
                                <a href="http://m.me/greenwoodlifeinsurance"
                                   className={"text-white align-items-center " + (collapse ? " mx-2" : " me-4")}
                                   target="_blank"><FontAwesomeIcon
                                    icon={brands("facebook-messenger")} size={"2x"}/></a>

                                <a href="http://t.me/+380502223281"
                                   className={"text-white align-items-center " + (collapse ? " mx-2" : "")}
                                   target="_blank"><FontAwesomeIcon
                                    icon={brands("telegram")} size={"2x"}/></a>
                            </div>
                        </div>

                    </div>
                    <div className={(collapse ? "main-content-collapsed" : "main-content")}>
                        <Routes>
                            <Route path="/" element={<Main/>}/>
                            <Route path="/index.html" element={<Main/>}/>
                            <Route path="/a" element={<Main/>}/>
                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/contracts" element={<Contracts/>}/>
                            <Route path="/contracts/:contractId" element={<Contract/>}/>

                            <Route path="/schedule" element={<Schedule/>}/>
                            <Route path="/insurance-payment" element={<Payments/>}/>
                            <Route path="/docs" element={<Docs/>}/>
                            <Route path="/alerts" element={<Alerts/>}/>
                            {/*Admin*/}
                            <Route path="/users" element={<Users/>}/>
                            <Route path="/clients" element={<Clients/>}/>
                            <Route path="/support" element={<SupportControl toggleUpdater={setNeedUpdate}/>}/>
                            <Route path="/req4change" element={<Req4ChangeControl toggleUpdater={setNeedUpdate}/>}/>
                            <Route path="/indexing" element={<IndexingControl toggleUpdater={setNeedUpdate}/>}/>
                            <Route path="/adm-insured-payment"
                                   element={<InsuredEvents toggleUpdater={setNeedUpdate}/>}/>
                        </Routes>
                    </div>
                    <Support/>
                </div>
            </UpdateCounterContext.Provider>
        </BrowserRouter>
    );
}

export default App;
