import React, {useEffect, useRef, useState} from "react";


export default function FileInput({index, cb, label=true, cbRem}){
    const [hasFile, setHasFile] = useState(false)
    const [fileName, setFileName] = useState("відсутній")

    const fileRef = useRef()

    const onChangeFile =()=>{
        if(fileRef.current.files){
            setHasFile(fileRef.current.files.length !== 0)
            setFileName(fileRef.current.files[0].name)
            return
        }
        if(!fileRef.current.files){
            setHasFile(false)
            setFileName("")
        }
    }

    useEffect(()=>{
        if(cb){
            if(fileRef.current.files.length !== 0){
                cb(index, fileRef.current.files[0].name, fileRef)
            }else{
                cb(index, null, null)
            }
        }
    }, [hasFile, fileName, index])

    return (<>
        {label &&
            <label className="form-label">
                Виберіть файл
            </label>
        }
        <div className="row mt-2">
            <div className="col-6 col-md-6">
                <input  className="form-control" type="file" name="con_file"
                        accept="image/*,application/pdf"
                        onChange={onChangeFile} ref={fileRef}/>

                {/*<span>*/}
                {/*  <strong>Вибраний файл: </strong>*/}
                {/*  <span id="file-name">{fileName}</span>*/}
                {/*</span>*/}

            </div>
            {cbRem &&
                <div className="col-1 col-md-1 offset-1 offset-md-1">
                    <button type="button" className="btn-close" aria-label="Close"
                            onClick={()=>cbRem(index)}></button>
                </div>
            }
        </div>
    </>)
}