import React, {useEffect, useState} from "react";
import "./ShortContracts.scss"
import Alert from "../../common/Alert";
import {Link} from "react-router-dom";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function ShortContracts() {
    const TYPE_IS_NOT_CLOSE = "is_not_close";
    const TYPE_ALL = "all";
    const [contractList, setContractList] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [typeShow, setTypeShow] = useState(TYPE_IS_NOT_CLOSE)

    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/contracts/short", {redirect: "error"},
            l => {
            if(l._embedded && l._embedded.shortContracts)
                setContractList(l._embedded.shortContracts)
            },
            reason => setErrorMsg(reason)
        )
    }, [])

    return (
        <>
            <div className={"row my-3"}>
                <div className="col-md-6">
                    <h4 className={"text-black-50 text-uppercase float-start mb-3"}>Договори страхування життя</h4>
                </div>
                <div className="col-md-6">
                    <div className="float-end">
                        <input name="type_show" autoComplete="off" type="radio" value="1" className="btn-check"
                               checked={typeShow === TYPE_IS_NOT_CLOSE} onChange={() => {
                            setTypeShow(TYPE_IS_NOT_CLOSE)
                        }}/>
                        <label className="btn btn-outline-secondary btn-sm me-3" onClick={() => {
                            setTypeShow(TYPE_IS_NOT_CLOSE)
                        }}>Діючі</label>

                        <input name="type_show" autoComplete="off" type="radio" value="2" className="btn-check"
                               checked={typeShow === TYPE_ALL} onChange={() => {
                            setTypeShow(TYPE_ALL)
                        }}/>
                        <label className="btn btn-outline-secondary btn-sm" onClick={() => {
                            setTypeShow(TYPE_ALL)
                        }}>Всі</label>
                    </div>
                </div>
            </div>

            {errorMsg &&
                <Alert header={"Помилка отримання даних!"} type={"danger"}>
                    {errorMsg}
                </Alert>
            }
            {contractList.filter(c => {
                if (typeShow === TYPE_ALL) return true
                if (typeShow === TYPE_IS_NOT_CLOSE && !c.isClose) return true
                return false
            }).map((c) => (
                <Link to={`/contracts/${c.id}`} className={"text-black text-decoration-none"} key={c.id}>
                    <div className={"row my-2 row-contract"}>
                        <div className="col-md-6">
                            <img className={"rounded-circle float-start m-2 me-4 contract_img"}
                                 src={"/img/" + resolveImg(c.programName)} alt=""/>
                            <div className={"my-3"}>
                                <strong>{c.printId}</strong><br/>
                                {c.programName}
                            </div>

                        </div>
                        <div className="col-md-6 py-4">
                            <div className="float-end text-black-50 ">
                                {c.status}
                            </div>

                        </div>
                    </div>
                </Link>
            ))
            }
        </>
    )
}

function resolveImg(prgName) {
    if (prgName.toLowerCase().includes("rich")) {
        return "rich_life.png"
    }

    if (prgName.toLowerCase().includes("invest")) {
        return "invest.png"
    }

    if (prgName.toLowerCase().includes("здоров'я") || prgName.toLowerCase().includes("травматизм")) {
        return "health.png"
    }

    return "my_life.png"
}