import React from "react";

export default function Address({address}){
    const a = address
    /*
    "address": {
        "zipCode": "49026",
        "passport": "АМ 263450 вид. Дніпропетровським РВУМВС України в Дніпропетровській обл. від 22.02.2001 р.",
        "apartment": null,
        "house": "буд. 19",
        "street": "вул. Івана Пулюя",
        "city": "м. Дніпро",
        "district": null,
        "region": null,
        "country": "Україна"
    },
     */
    return (
        <>
            {a.zipCode}, {a.region && <>{a.region}, </>}{a.district && <>{a.district},</>}{a.city}, {a.street && <>{a.street}, </>}{a.house && <>{a.house}</>}{a.apartment && <>, {a.apartment}</>}
        </>
    )
}