import React, {useEffect, useState} from "react";
import Header from "../../../header/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import Alert from "../../../common/Alert";
import {Modal} from "bootstrap";
import SelectClient from "./SelectClient";
import SelectRole from "./SelectRole";
import CrudNav from "../common/CrudNav";
import WrapperFetch from "../../../../srv_tools/WrapperFetch";

export default function Users() {
    //region page with tb
    const [users, setUsers] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(true)
    const [info, setInfo] = useState(null)
    const [page, setPage] = useState({totalPages: 0})
    const [size, setSize] = useState(20)
    const [phone, setPhone] = useState("")

    useEffect(() => {
        if (!(phone && phone.length > 5)) return;
        getUsers(0)
    }, [phone])

    const getUsers = function (p) {
        setLoading(true)
        let num = phone.replace('+', '');

        WrapperFetch.getInstance().enqueue(`/admin/api/v1/users?page=${p}&size=${size}&phone=${num}`, {redirect: "error"},
            (d) => {
                setLoading(false)
                if (d.page)
                    setPage(d.page);
                setUsers(d._embedded && d._embedded.users ? d._embedded.users : [])
            },
            reason => setErrorMsg(reason))
    }

    const timeReform = function (d) {
        //2022-05-21T19:11:07.000+00:00
        if (!d) return;
        return d.split('.')[0].split('T').join(' ')
    }

    const showUserInfo = function (u) {
        setInfo(u)
        let myModalEl = document.querySelector('#user_info')
        let modal = Modal.getOrCreateInstance(myModalEl)
        modal.show()
    }

    const roleFormat = function (roles) {
        //ROLE_USER,ROLE_ADMIN
        return roles.split(',').map((r) => r.split('_')[1]).join(', ')
    }

    const statusFormat = function (status) {
        switch (status) {
            case "BLOCKED":
                return "Заблокован"
            case "ACTIVE":
                return "Активний"
            default:
                return "Невідомий"
        }
    }

    // useEffect(() => {
    //     getUsers(0)
    // }, [size])
    //endregion

    //region create user modal
    const createUserShow = function () {
        let myModalEl = document.querySelector('#create_user')
        let modal = Modal.getOrCreateInstance(myModalEl)
        modal.show()
    }
    const [cuPhone, setCuPhone] = useState("")
    const [cuStatus, setCuStatus] = useState("BLOCKED")
    const [cuRole, setCuRole] = useState(["ROLE_USER"])
    const [loading3, setLoading3] = useState(false)
    const [errorMsg2, setErrorMsg2] = useState("")

    const isValidCuForm = function () {
        if (!cuPhone || cuPhone.length < 10) return false
        if (!cuRole || cuRole.length === 0) return false
        return true
    }

    const createUser = function () {
        setLoading3(true)
        const req = {phone: cuPhone, status: cuStatus, roles: cuRole.join(",")}
        fetch("/admin/api/v1/authPhones", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            redirect: "error"
        })
            .catch(reason => setErrorMsg2(reason))
            .then(r => {
                if (r.status === 201) {
                    Modal.getInstance(document.getElementById('create_user')).hide()
                    getUsers(page.number)
                    return
                }
                return r.json();
            }).then(r => {
            if (!r) {
                return;
            }
            if (r.error) {
                setErrorMsg2(r.error)
                setTimeout(() => setErrorMsg2(""), 10000)
                return;
            }
        })
    }
    //endregion

    const selectClient = function (username, client) {
        const req = {phone: username, personId: client.value}
        fetch("/admin/api/v1/authPhones/client", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            redirect: "error"
        })
            .catch(reason => setErrorMsg(reason))
            .then(() => getUsers(page.number))
    }

    const removeClient = function (username) {
        if (window.confirm("Клієнт буде відв'язан від користувача. Ви впевнені?")) {
            selectClient(username, {value: null})
        }
    }

    const changeStatus = function (username, status) {
        const req = {phone: username, status: status}
        fetch("/admin/api/v1/authPhones/status", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            redirect: "error"
        })
            .catch(reason => setErrorMsg(reason))
            .then(() => getUsers(page.number))
    }

    const selectRoles = function (username, roles) {
        const req = {phone: username, roles: roles.join(',')}
        fetch("/admin/api/v1/authPhones/roles", {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            redirect: "error"
        })
            .catch(reason => setErrorMsg(reason))
            .then(() => getUsers(page.number))
    }

    const loginBy = function (username) {
        const usr = username.replace('+', "%2B")
        fetch(`/admin/api/v1/loginByUser?u=${usr}`, {
            redirect: "error"
        })
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then(r => {
                if (r.error) {
                    setErrorMsg(r.error)
                    return
                }
                if (r.ok) {
                    window.localStorage['user'] = ''
                    window.location.replace("/");
                }
            })
    }

    return (<>
        <div className="row mx-2">
            <div className="col-12 p-2 page-header">
                <Header showConsultant={false}/>
            </div>
        </div>
        <div className="row g-0 mx-2 my-2">
            <div className="col-12 p-2 px-5 page-block-min">
                <div className="my-3">
                    <div className="clearfix mb-2">
                        <h4 className={"text-black-50 float-start"}>Користувачі</h4>
                        <button onClick={() => createUserShow()} title="Створити нового користувача"
                                className="btn btn-success float-end">
                            <FontAwesomeIcon
                                icon={solid("plus")}/>
                        </button>
                    </div>
                    <div className={"border p-3"}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <h5>Пошук</h5>
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="row">
                                        <label htmlFor="search_by_phone"
                                               className="col-sm-4 col-form-label">Телефон</label>
                                        <div className="col-sm-8">
                                            <input id="search_by_phone" value={phone}
                                                   onChange={event => setPhone(event.target.value)} type="text"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <button className={"btn btn-success"} onClick={() => getUsers(page.number)}>
                                        <FontAwesomeIcon icon={solid("search")}/>
                                    </button>
                                </div>
                                <div className="offset-sm-1 col-sm-4">
                                    <div className="row">
                                        <label htmlFor="row_on_page" className={"col-sm-8 col-form-label text-end"}>Зап.
                                            на ст.</label>
                                        <div className="col-sm-4">
                                            <select id="row_on_page" value={size}
                                                    onChange={(e) => setSize(e.target.value)} className="form-control">
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                        {errorMsg}
                    </Alert>}
                    <div className="table-responsive">
                        <table className="table mt-4 table-hover">
                            <thead>
                            <tr>
                                <th>Телефон</th>
                                <th>Статус</th>
                                <th>Ролі</th>
                                <th>Останній логін</th>
                                <th>Клієнт</th>
                                <th>Операції</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((u) => (<tr key={u.username}>
                                <td>{u.username}</td>
                                <td><span
                                    className={u.authPhone.status === "ACTIVE" ? "text-success" : "text-danger"}>{statusFormat(u.authPhone.status)}</span>
                                </td>
                                <td>{roleFormat(u.authPhone.roles)}</td>
                                <td>{timeReform(u.authPhone.lastLogin)}</td>
                                <td>
                                    {u.person && u.client && <>
                                        id: {u.client.clientId}
                                        <br/>
                                        {u.client.clName}
                                        <button className={"btn btn-success ms-2"} title="Переглянути дані клієнта"
                                                onClick={() => showUserInfo(u)}>
                                            <FontAwesomeIcon icon={solid("eye")}/>
                                        </button>
                                        <button className={"btn btn-warning ms-1"} title="Видалити привʼязку"
                                                onClick={() => removeClient(u.username)}>
                                            <FontAwesomeIcon icon={solid("minus")}/>
                                        </button>
                                    </>}
                                    {!u.client && <SelectClient resultHandle={(cl) => selectClient(u.username, cl)}/>}
                                </td>
                                <td>

                                    {u.authPhone.status === "ACTIVE" &&
                                        <button className={"btn btn-danger"} title="Заблокувати користувача"
                                                onClick={() => changeStatus(u.username, "BLOCKED")}><FontAwesomeIcon
                                            icon={solid("user-slash")}/></button>}
                                    {u.authPhone.status !== "ACTIVE" &&
                                        <button className={"btn btn-success"} title="Активувати користувача"
                                                onClick={() => changeStatus(u.username, "ACTIVE")}><FontAwesomeIcon
                                            icon={solid("user-check")}/></button>}

                                    <SelectRole currentRoles={u.authPhone.roles}
                                                resultHandle={(roles) => selectRoles(u.username, roles)}/>
                                    {u.client &&
                                        <button onClick={() => loginBy(u.username)} title="Увійти від імʼя користувача"
                                                className={"btn btn-warning ms-1"}>
                                            <FontAwesomeIcon icon={solid("arrow-right-to-bracket")}/>
                                        </button>}
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                    <CrudNav size={size} page={page} getData={getUsers}/>
                    {loading && <div className="d-flex justify-content-center">
                        <div className="spinner-border text-success " role="status">
                            <span className="visually-hidden">Завантаження...</span>
                        </div>
                    </div>}
                </div>
            </div>

        </div>

        <div className="modal fade" id="user_info" tabIndex="-1" aria-labelledby="userInfoLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="userInfoLabel">Інформація про клієнта</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {info &&
                            <table className="table table-hover">
                                <tbody>
                                <tr>
                                    <th>ID</th>
                                    <td>{info.client.clientId}</td>
                                </tr>
                                <tr>
                                    <th>Ім'я</th>
                                    <td>{info.client.clName}</td>
                                </tr>

                                <tr>
                                    <th>Телефон</th>
                                    <td>{info.person.phoneMob}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{info.person.email}</td>
                                </tr>
                                <tr>
                                    <th>Народження</th>
                                    <td>{info.person.birthday}</td>
                                </tr>
                                <tr>
                                    <th>Адреса реєстрації</th>
                                    <td>{info.person.country} {info.person.zipCode} {info.person.city} {info.person.street}</td>
                                </tr>
                                <tr>
                                    <th>Адреса проживання</th>
                                    <td>{info.person.realCountry} {info.person.realZipCode} {info.person.realCity} {info.person.realStreet}</td>
                                </tr>
                                <tr>
                                    <th>Паспорт</th>
                                    <td>{info.person.passport}</td>
                                </tr>
                                <tr>
                                    <th>ІПН</th>
                                    <td>{info.person.taxID}</td>
                                </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="create_user" tabIndex="-1" aria-labelledby="createUserLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="createUserLabel">Створити нового користувача</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="create_user_phone" className="form-label">Телефон</label>
                            <input type="text" className="form-control" id="create_user_phone"
                                   aria-describedby="cuPhoneHelp"
                                   value={cuPhone} onChange={event => setCuPhone(event.target.value)}
                            />
                            <div id="cuPhoneHelp" className="form-text">
                                Телефон користувача для укр номерів може будти у 10-ти символьному форматі (напр.:
                                0631112233), а також
                                у міжнородному форматі 13 символів і починатися с + (напр.: +30631112233). Не укр номери
                                повинні бути
                                лише у міжнородному фоматі 13 символів.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="cu_status" className="form-label">Статус</label>
                            <select className="form-select" name="" id="cu_status"
                                    value={cuStatus} onChange={event => setCuStatus(event.target.value)}
                            >
                                <option value="BLOCKED">Заблокован</option>
                                <option value="ACTIVE">Активний</option>
                            </select>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="cu_roles" className="form-label">Роль</label>
                            <select className="form-select" multiple name="" id="cu_roles"
                                    value={cuRole} onChange={event => {
                                setCuRole([...event.target.options].filter(({selected}) => selected).map(({value}) => value))
                            }}
                            >
                                <option value="ROLE_USER">Користувач</option>
                                <option value="ROLE_ADMIN">Адмін</option>
                            </select>
                            <div id="cuPhoneHelp" className="form-text">
                                Для множинного вибору використовуйте <mark>ctrl+click</mark>,
                                або <mark>shift+click</mark>
                            </div>
                        </div>
                        {errorMsg2 && <Alert header={"Помилка обробки даних!"} type={"danger"} addClass={"my-4"}>
                            {errorMsg2}
                        </Alert>}
                    </div>
                    <div className="modal-footer">
                        {loading3 && <div className="d-flex justify-content-start me-4">
                            <div className="spinner-border text-success " role="status">
                                <span className="visually-hidden">Завантаження...</span>
                            </div>
                        </div>}
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                        <button type="button" className="btn btn-success" disabled={!isValidCuForm()}
                                onClick={() => createUser()}>Створити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}