import React from "react";
import Address from "./Address";

export default function Participant({participant, client, event="", percent, is_real=false}){
    const p = participant
    return (
        <div className={"row"}>
            <div className="col-md-4" >
                {participant && <>
                    <span className={"fw-bold"}>{p.surname} {p.firstname} {p.fathername}</span><br/>
                    {p.birthday}
                </>}
                {client &&
                <span className={"fw-bold"}>{client.clName}</span>
                }
                {is_real && (participant || client) && <><br/></>}
                {is_real &&
                    <span className={"fw-light"}>Законні спадкоємці</span>
                }
            </div>
            <div className="col-md-2" >{event}</div>
            <div className="col-md-1" >{percent && <>{percent}%</>}</div>
            <div className="col-md-4" >
                {participant && <Address address={p.address} />}
            </div>
            <div className="col-md-1" ></div>
        </div>
    )
}