import React, {useEffect, useRef, useState} from "react";
import {Modal} from "bootstrap";
import FileInput from "./FileInput";
import Alert from "../../common/Alert";

export default function FioChange({surname, firstname, fathername, cb}) {
    useEffect(() => {
        setTimeout(() => {
            let dm = document.querySelector('#fioModal')
            let m = Modal.getOrCreateInstance(dm)
            m.show()
            dm.addEventListener('hidden.bs.modal', function (e){
                if(cb){
                    cb()
                }
            })
        }, 10)

    }, [])

    const [reason, setReason] = useState("marriage")
    const otherReasonInput = useRef()

    const [nsurname, setNsurname] = useState('')
    const [nfirtstname, setNfirstname] = useState('')
    const [nfathername, setNfathername] = useState('')
    const [otherReason, setOtherReason] = useState('')

    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (reason === "other") {
            otherReasonInput.current.focus();
        }
    }, [reason])
    const [files, setFiles] = useState([null])

    const onChangeFile = (index, name, fileRef) => {
        let nf = []
        files.forEach(f => nf.push(f))
        nf[index] = fileRef
        if (name !== null && nf.length <= index + 1) {
            nf.push(null)
        }
        setFiles(nf)
    }

    const onRem = (index) => {
        let nf = []
        files.forEach((f, i) => {
            if (i !== index) nf.push(f)
        })
        setFiles(nf)
    }

    useEffect(() => {
        if (reason === "other") {
            if (otherReason.length <= 10) {
                setReady(false)
                return
            }
        }
        if (nsurname.length < 2 ||
            nfirtstname.length < 2 ||
            nfathername.length < 2) {
            setReady(false)
            return
        }
        let fileOk = false
        files
            .filter(f => f !== null)
            .forEach(
                f => {
                    fileOk = true
                }
            )
        setReady(fileOk)
    }, [reason, nsurname, nfirtstname, nfathername, files, otherReason])

    const [errorMsg, setErrorMsg] = useState("")
    const [errorTitle, setErrorTitle] = useState("Помилка отримання даних!")
    const [loading, setLoading] = useState("")

    const sendReq = () => {
        setErrorMsg("")
        setLoading("Створюємо запит на зміну ваших даних...")
        const req = {
            fields: [
                {surname: nsurname}, {firstname: nfirtstname}, {fathername: nfathername}
            ], name: "pib", hasFiles: true, reason, reasonAdd: otherReason
        }
        fetch("/api/v1/change",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req),
                redirect: "error"
            }
        )
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then(d => {
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setLoading("")
                    setTimeout(()=>setErrorMsg(""), 8000)
                    return
                }
                if (d.token)
                    return d.token

                if (d.detail) {
                    setErrorMsg(d.detail)
                    if (d.title) {
                        setErrorTitle(d.title)
                    }
                    setLoading("")
                    setTimeout(()=>setErrorMsg(""), 8000)
                    return
                }

                setErrorMsg("Виникла несподівана помилка під час обробки вашого запиту. Зверніться із запитом в підтримку.")
                setTimeout(()=>setErrorMsg(""), 8000)
                setLoading("")
                return
            })
            .then(token => {
                if (!token) {
                    return
                }
                setLoading("Завантажуємо ваші файли...")
                files
                    .filter(f => f !== null)
                    .forEach((f, i) => {
                        setLoading("Завантажуємо ваші файли...")
                        let data = new FormData()
                        data.append('file', f.current.files[0])
                        data.append('token', token)
                        data.append('index', i)
                        fetch("/api/v1/change/upload", {
                            method: 'POST',
                            body: data,
                            redirect: "error"
                        })
                            .catch(reason => setErrorMsg(reason))
                            .then(r => r.json())
                            .then(d => {
                                setLoading("")
                                if (d.error) {
                                    setErrorMsg(d.error)
                                    return;
                                }
                                if (d.detail) {
                                    setErrorMsg(d.detail)
                                }
                            })
                    })
                if (!errorMsg) {
                    Modal.getOrCreateInstance(document.querySelector('#fioModal')).hide()

                    if(cb)
                        setTimeout(()=>{
                            cb('Ваш запит успішно створено і очікує обробки адміністратора')}, 500)

                }
            })
    }

    return (<>
        <div className="modal fade" id="fioModal" tabIndex="-1" aria-labelledby="fioModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="fioModalLabel">Зміна ПІБ Страхувальника</h5>
                        {cb &&
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        }
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="client_fio" className="form-label fw-bold">Поточне ПІБ
                                        Страхувальника</label>
                                    <div className="row">
                                        <div className="col-4 col-md-4">
                                            <input type="text" className="form-control" id="client_fio"
                                                   value={surname}
                                                   disabled={true}/>
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <input type="text" className="form-control" id="client_firstname"
                                                   value={firstname}
                                                   disabled={true}/>
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <input type="text" className="form-control" id="client_fathername"
                                                   value={fathername}
                                                   disabled={true}/>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="client_new_fio" className="form-label fw-bold">Нове ПІБ
                                        Страхувальника</label>
                                    <div className="row">
                                        <div className="col-4 col-md-4">
                                            <input type="text" className="form-control" id="client_new_fio"
                                                   placeholder="Прізвище"
                                                   value={nsurname} onChange={event => setNsurname(event.target.value)}
                                            />
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <input type="text" className="form-control" id="client_new_firstname"
                                                   placeholder="Ім'я"
                                                   value={nfirtstname}
                                                   onChange={event => setNfirstname(event.target.value)}
                                            />
                                        </div>
                                        <div className="col-4 col-md-4">
                                            <input type="text" className="form-control" id="client_new_fathername"
                                                   placeholder="По-батькові"
                                                   value={nfathername}
                                                   onChange={event => setNfathername(event.target.value)}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="change_variant" className="form-label fw-bold">Причина зміни
                                        ПІБ</label>
                                    <div className="row">
                                        <div className="col-4 col-md-4">
                                            <select className="form-control" name="" id="change_variant" value={reason}
                                                    onChange={event => setReason(event.target.value)}>
                                                <option value="marriage">Шлюб</option>
                                                <option value="divorce">Розлучення</option>
                                                <option value="name_change">Зміна імені</option>
                                                <option value="error">Помилка в полісі</option>
                                                <option value="other">Інше (вказати)</option>
                                            </select>
                                        </div>
                                        <div className="col-8 col-md-8">
                                            <input type="text" className="form-control" id="fio_other"
                                                   placeholder="Інше"
                                                   value={otherReason}
                                                   onChange={event => setOtherReason(event.target.value)}
                                                   readOnly={reason !== "other"} ref={otherReasonInput}/>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="change_variant" className="form-label fw-bold">Додатки</label>
                                    <p className={"form-text"}>Будь ласка, додайте копію сторінок нового паспорта, копію
                                        свідоцтва про шлюб, розлучення тощо.</p>
                                    {files.map((f, i) => (<FileInput key={i} index={i} cb={onChangeFile} label={i === 0}
                                                                     cbRem={i !== 0 ? onRem : null}/>))}

                                </div>
                            </div>
                        </div>
                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                    <div className="modal-footer d-flex justify-content-center text-center">

                        <button type="button" className="btn btn-primary" disabled={!ready}
                                onClick={() => sendReq()}
                        >Змінити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
