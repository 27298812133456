import React, {useEffect, useState} from "react";
import Header from "../../../header/Header";
import Alert from "../../../common/Alert";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "bootstrap";
import CrudNav from "../common/CrudNav";
import WrapperFetch from "../../../../srv_tools/WrapperFetch";

export default function Clients() {
    const [clients, setClients] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(true)
    const [info, setInfo] = useState(null)
    const [page, setPage] = useState({})
    const [size, setSize] = useState(20)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [person, setPerson] = useState(null)
    const [loading2, setLoading2] = useState(true)
    const [users, setUsers] = useState([])
    useEffect(() => {
        if (!(name && name.length > 2)) return;
        setPhone("")
        setEmail("")
        if (!(name && name.length > 5)) return;
        getClients(page.number)
    }, [name])

    useEffect(() => {
        if(!(phone && phone.length > 2)) return;
        setName("")
        getClients(page.number)
    }, [phone])

    useEffect(() => {
        if(!(email && email.length > 3)) return;
        setName("")
        getClients(page.number)
    }, [email])

    const getClients = function (p) {
        setLoading(true)
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/clients?page=${p}&size=${size}&clName=${name}&phone=${phone}&email=${email}`,
            {redirect: "error"},
                d => {
                    setLoading(false)
                    if (d.error) {
                        setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                        setClients([])
                        return
                    }
                    if (d.page)
                        setPage(d.page);
                    setClients(d._embedded && d._embedded.clients ? d._embedded.clients : [])
                },
            reason => setErrorMsg(reason));
    }

    useEffect(() => {
        const pids = clients.map(c => c.clientId).join(',')
        const res = []
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/authPhones?p=${pids}`,
            {redirect: "error"},
            d => {
                let obj = d._embedded && d._embedded.authPhoneViews ? d._embedded.authPhoneViews : []
                obj.forEach(a => res[a.personId] = a.phone)
                setUsers(res)
            },
            reason => setErrorMsg(reason));
    }, [clients])

    // useEffect(() => {
    //     getClients(0)
    // }, [size])

    const showClientInfo = function (c) {
        setPerson(null)
        loadPerson(c.clientId)
        setInfo(c)
        let myModalEl = document.querySelector('#client_info')
        let modal = Modal.getOrCreateInstance(myModalEl)
        modal.show()
    }

    const loadPerson = function (id) {
        setLoading2(true)
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/persons/${id}`,
            {redirect: "error"},
            d => {
                setPerson(d)
                setLoading2(false)
                console.log("point 111")
            },
            reason => setErrorMsg(reason));
    }

//region createUser
    const createUserShow = function (personId) {
        let myModalEl = document.querySelector('#create_user')
        let modal = Modal.getOrCreateInstance(myModalEl)
        setCuPersonId(personId)
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/persons/${personId}`,
            {redirect: "error"},
            p => setCuPhone(p.phoneMob),
            reason => setErrorMsg(reason));
        modal.show()
    }
    const isValidCuForm = function () {
        if (!cuPhone || cuPhone.length < 10) return false
        if (!cuRole || cuRole.length === 0) return false
        return true
    }
    const [cuPersonId, setCuPersonId] = useState("")
    const [cuPhone, setCuPhone] = useState("")
    const [cuStatus, setCuStatus] = useState("BLOCKED")
    const [cuRole, setCuRole] = useState(["ROLE_USER"])
    const [loading3, setLoading3] = useState(false)
    const [errorMsg2, setErrorMsg2] = useState("")

    const createUser = function () {
        setLoading3(true)
        const req = {phone: cuPhone, status: cuStatus, roles: cuRole.join(","), personId: cuPersonId}
        fetch("/admin/api/v1/authPhones", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            redirect: "error"
        })
            .catch(reason => setErrorMsg2(reason))
            .then(r => {
                if (r.status === 201) {
                    Modal.getInstance(document.getElementById('create_user')).hide()
                    getClients(page.number)
                    setLoading3(false)
                    return
                }
                return r.json();
            }).then(r => {
            if (!r) {
                return;
            }
            if (r.error) {
                setErrorMsg2(r.error)
                setTimeout(() => setErrorMsg2(""), 10000)
                return;
            }
        })
    }
//endregion
    return (<>
        <div className="row mx-2">
            <div className="col-12 p-2 page-header">
                <Header showConsultant={false}/>
            </div>
        </div>
        <div className="row g-0 mx-2 my-2">
            <div className="col-12 p-2 px-5 page-block-min">
                <div className="my-3">
                    <h4 className={"text-black-50"}>Клієнти</h4>
                    <div className={"border p-3"}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <h5>Пошук</h5>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="row">
                                        <label htmlFor="search_by_name"
                                               className="col-sm-3 col-form-label">Ім'я</label>
                                        <div className="col-sm-9">
                                            <input id="search_by_name" value={name}
                                                   onChange={event => setName(event.target.value)} type="text"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="row">
                                        <label htmlFor="search_by_phone"
                                               className="col-sm-3 col-form-label">Телефон</label>
                                        <div className="col-sm-9">
                                            <input id="search_by_phone" value={phone}
                                                   onChange={event => setPhone(event.target.value)} type="text"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="row">
                                        <label htmlFor="search_by_email"
                                               className="col-sm-3 col-form-label">Email</label>
                                        <div className="col-sm-9">
                                            <input id="search_by_email" value={email}
                                                   onChange={event => setEmail(event.target.value)} type="text"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1">
                                    <button className={"btn btn-success"} onClick={() => getClients(page.number)}>
                                        <FontAwesomeIcon
                                            icon={solid("search")}/>
                                    </button>
                                </div>
                                <div className="col-sm-2">
                                    <div className="row">
                                        <label htmlFor="row_on_page" className={"col-sm-8 col-form-label text-end"}>Зап.
                                            на
                                            ст.</label>
                                        <div className="col-sm-4">
                                            <select id="row_on_page" value={size}
                                                    onChange={(e) => setSize(e.target.value)}
                                                    className="form-control">
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                        {errorMsg}
                    </Alert>}
                    <div className="table-responsive">
                        <table className="table mt-4 table-hover">
                            <thead>
                            <tr>
                                <th>Ід</th>
                                <th>Ім'я</th>
                                <th>Тип</th>
                                <th className={"text-center"}>Резидент</th>
                                <th>Користувач</th>
                                <th>Операції</th>
                            </tr>
                            </thead>
                            <tbody>
                            {clients.map((c) => (<tr key={c.clientId}>
                                <td>{c.clientId}</td>
                                <td>{c.clName}</td>
                                <td>{c.clType}</td>
                                <td className={"text-center"}>{c.isResident && <FontAwesomeIcon
                                    icon={solid("plus")}/>}{!c.isResident && <FontAwesomeIcon
                                    icon={solid("minus")}/>}</td>
                                <td>
                                    {users[c.clientId] && <>{users[c.clientId]}</>}
                                    {!users[c.clientId] &&
                                        <button className={"btn btn-success"} title="Створити користувоча"
                                                onClick={() => createUserShow(c.clientId)}><FontAwesomeIcon
                                            icon={solid("plus")}/></button>}
                                </td>
                                <td>
                                    <button className={"btn btn-light"} onClick={() => showClientInfo(c)}>
                                        <FontAwesomeIcon
                                            icon={solid("eye")}/></button>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                    <CrudNav size={size} page={page} getData={getClients} />

                    {loading && <div className="d-flex justify-content-center">
                        <div className="spinner-border text-success " role="status">
                            <span className="visually-hidden">Завантаження...</span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>

        <div className="modal fade" id="client_info" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="clientInfoLabel">Інформація про клієнта</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {info &&
                            <table className="table table-hover">
                                <tbody>
                                <tr>
                                    <th>ID</th>
                                    <td>{info.clientId}</td>
                                </tr>
                                <tr>
                                    <th>Ім'я</th>
                                    <td>{info.clName}</td>
                                </tr>
                                <tr>
                                    <th>Тип</th>
                                    <td>{info.clType}</td>
                                </tr>
                                <tr>
                                    <th>Резидент</th>
                                    <td>{info.isResident && <FontAwesomeIcon
                                        icon={solid("plus")}/>}{!info.isResident && <FontAwesomeIcon
                                        icon={solid("minus")}/>}</td>
                                </tr>
                                {person && <>
                                    <tr>
                                        <th>Телефон</th>
                                        <td>{person.phoneMob}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{person.email}</td>
                                    </tr>
                                    <tr>
                                        <th>Народження</th>
                                        <td>{person.birthday}</td>
                                    </tr>
                                    <tr>
                                        <th>Адреса реєстрації</th>
                                        <td>{person.country} {person.zipCode} {person.city} {person.street}</td>
                                    </tr>
                                    <tr>
                                        <th>Адреса проживання</th>
                                        <td>{person.realCountry} {person.realZipCode} {person.realCity} {person.realStreet}</td>
                                    </tr>
                                    <tr>
                                        <th>Паспорт</th>
                                        <td>{person.passport}</td>
                                    </tr>
                                    <tr>
                                        <th>ІПН</th>
                                        <td>{person.taxID}</td>
                                    </tr>
                                </>}
                                </tbody>
                            </table>
                        }
                        {loading2 && <div className="d-flex justify-content-center">
                            <div className="spinner-border text-success " role="status">
                                <span className="visually-hidden">Завантаження...</span>
                            </div>
                        </div>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="create_user" tabIndex="-1" aria-labelledby="createUserLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="createUserLabel">Створити нового користувача</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="create_user_phone" className="form-label">Телефон</label>
                            <input type="text" className="form-control" id="create_user_phone"
                                   aria-describedby="cuPhoneHelp"
                                   value={cuPhone} onChange={event => setCuPhone(event.target.value)}
                            />
                            <div id="cuPhoneHelp" className="form-text">
                                Телефон користувача для укр номерів може будти у 10-ти символьному форматі (напр.:
                                0631112233), а також
                                у міжнородному форматі 13 символів і починатися с + (напр.: +30631112233). Не укр номери
                                повинні бути
                                лише у міжнородному фоматі 13 символів.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="cu_status" className="form-label">Статус</label>
                            <select className="form-select" name="" id="cu_status"
                                    value={cuStatus} onChange={event => setCuStatus(event.target.value)}
                            >
                                <option value="BLOCKED">Заблокован</option>
                                <option value="ACTIVE">Активний</option>
                            </select>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="cu_roles" className="form-label">Роль</label>
                            <select className="form-select" multiple name="" id="cu_roles"
                                    value={cuRole} onChange={event => {
                                setCuRole([...event.target.options].filter(({selected}) => selected).map(({value}) => value))
                            }}
                            >
                                <option value="ROLE_USER">Користувач</option>
                                <option value="ROLE_ADMIN">Адмін</option>
                            </select>
                            <div id="cuPhoneHelp" className="form-text">
                                Для множинного вибору використовуйте <mark>ctrl+click</mark>,
                                або <mark>shift+click</mark>
                            </div>
                        </div>
                        {errorMsg2 && <Alert header={"Помилка обробки даних!"} type={"danger"} addClass={"my-4"}>
                            {errorMsg2}
                        </Alert>}
                    </div>
                    <div className="modal-footer">
                        {loading3 && <div className="d-flex justify-content-start me-4">
                            <div className="spinner-border text-success " role="status">
                                <span className="visually-hidden">Завантаження...</span>
                            </div>
                        </div>}
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                        <button type="button" className="btn btn-success" disabled={!isValidCuForm()}
                                onClick={() => createUser()}>Створити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}