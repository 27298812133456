import React, {useContext, useEffect, useState} from "react";
import './Menu.scss';
import {NavLink} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "../common/Alert";
import {Tooltip} from "bootstrap";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import WrapperFetch from "../../srv_tools/WrapperFetch";

var tt = []
var timeout ;
export default function Menu({menu, adm, collapse = false, mob=false, counts={}, tbBal}) {
    const [user, setUser] = useState({clName: " "})
    const [isAdmin, setIsAdmin] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")


    useEffect(() => {
        if (collapse) {
            let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            tt = tooltipTriggerList.map(function (tooltipTriggerEl, i) {
                let tooltip = new Tooltip(tooltipTriggerEl)
                if(tt.length !== 0){
                    tooltipTriggerEl.addEventListener('shown.bs.tooltip', function (e) {
                    if(!timeout) {
                        timeout = setTimeout(function () {
                            document.querySelectorAll('[role="tooltip"]').forEach(e => e.remove());
                            timeout = null;
                        }, 2000)
                    }
                    });
                }
                return tooltip
            })
        }else{
            tt.forEach(t=>{try{t.dispose()}catch (e) {
                
            }})
        }
    }, [collapse])


    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/roles", {redirect: "error"},
            data => {
                data._embedded.strings.map((r) => r.split('_')[1]).filter(r => r === 'ADMIN').forEach(() => setIsAdmin(true));
            },
            reason => setErrorMsg(reason))
    }, [])



    useEffect(() => {
        if (isAdmin) {
            if (window.localStorage['user'] && window.localStorage['user'].length >= 2)
                setUser(JSON.parse(window.localStorage['user']));
        }
    }, [isAdmin]);

    if(mob){
        return (
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link active" aria-current="page" href="#">Home</a>*/}
                {/*</li>*/}
                {user.clName && <>
                    {menu.map((item) => (
                        <li className={"nav-item "} key={item.id}>
                            <NavLink
                                className={({isActive}) => (isActive ? "nav-link text-black active p-1" : "nav-link text-white p-1")}
                                to={item.to} title={item.name} >
                                <FontAwesomeIcon icon={item.icon}
                                                 size={"1x"}/> {!collapse && <>{item.name}</>}
                            </NavLink>
                        </li>
                    ))}
                </>}

                {isAdmin && <>
                    <hr/>
                    {!collapse &&
                        <h4 className={"text-white"}>Admin</h4>}
                    {adm.map((item) => (
                        <li className={"nav-item"} key={item.id}>
                            <NavLink
                                className={({isActive}) => (isActive ? "nav-link text-black active position-relative p-1" : "nav-link text-white p-1 position-relative")}
                                to={item.to} title={item.name} data-bs-toggle={(collapse ? "tooltip" : "")}
                                data-bs-placement="right">
                                <FontAwesomeIcon icon={item.icon} size={(collapse ? "2x" : "1x")}/> {!collapse && <>{item.name}</>}
                                {counts[item.id]>0 &&
                                    <span className={"ms-3 badge rounded-pill bg-danger position-absolute translate-middle top-50" }>
                                    {counts[item.id]}
                                 </span>
                                }
                            </NavLink>
                        </li>
                    ))}

                    {tbBal >= 0.0 && <li className={"mt-5 ms-2 text-white"}>
                    <FontAwesomeIcon icon={solid("money-bill")} size={(collapse ? "2x" : "1x")}/> Balance: {tbBal}
                    </li>}
                </>
                }
            </ul>
        )
    }

    return (
        <ul className={"nav  nav-flush flex-column mb-auto" + (collapse ? " text-center" : " nav-pills")}>
            {user.clName && <>
                {menu.map((item) => (
                    <li className={"nav-item"} key={item.id} data-bs-toggle={(collapse ? "tooltip" : "")}
                        data-bs-placement="right" title={item.name}>
                        <NavLink
                            className={({isActive}) => (isActive ? "nav-link text-black active" : "nav-link text-white") + (collapse ? " border-bottom py-3" : "")}
                            to={item.to} title={item.name} >
                            <FontAwesomeIcon icon={item.icon}
                                             size={(collapse ? "2x" : "1x")}/> {!collapse && <>{item.name}</>}
                        </NavLink>
                    </li>
                ))}
            </>}
            {errorMsg &&
                <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                    {errorMsg}
                </Alert>
            }
            {isAdmin && <>
                <hr/>
                {!collapse &&
                <h4>Admin</h4>}
                {adm.map((item) => (
                    <li className={"nav-item"} key={item.id}>
                        <NavLink
                            className={({isActive}) => (isActive ? "nav-link text-black active position-relative" : "nav-link text-white position-relative") + (collapse ? " border-bottom py-3" : "")}
                            to={item.to} title={item.name} data-bs-toggle={(collapse ? "tooltip" : "")}
                            data-bs-placement="right">
                            <FontAwesomeIcon icon={item.icon} size={(collapse ? "2x" : "1x")}/> {!collapse && <>{item.name}</>}
                            {counts[item.id]>0 &&
                                <span className={"ms-3 badge rounded-pill bg-danger position-absolute translate-middle top-50" }>
                                    {counts[item.id]}
                                 </span>
                            }
                        </NavLink>
                    </li>
                ))}

                {tbBal >= 0.0 && <li className={"mt-5 ms-3"}>
                    <FontAwesomeIcon icon={solid("money-bill")} size={(collapse ? "2x" : "1x")}/> Balance: {tbBal}
                </li>}
            </>
            }

        </ul>
    )
}