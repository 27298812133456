import React, {useEffect, useState} from "react";
import {Modal} from "bootstrap";
import Alert from "../../common/Alert";
import FileInput from "./FileInput";

export default function AddressChange({address, isReal = false, cb}) {
    useEffect(() => {
        setTimeout(() => {
            let dm = document.querySelector('#addressChangeModal')
            let m = Modal.getOrCreateInstance(dm)
            m.show()
            dm.addEventListener('hidden.bs.modal', function (e) {
                if (cb) {
                    cb()
                }
            })
        }, 10)

    }, [])
    const [ready, setReady] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [errorTitle, setErrorTitle] = useState("Помилка отримання даних!")
    const [loading, setLoading] = useState("")
    const [files, setFiles] = useState([null])

    const onChangeFile = (index, name, fileRef) => {
        let nf = []
        files.forEach(f => nf.push(f))
        nf[index] = fileRef
        if (name !== null && nf.length <= index + 1) {
            nf.push(null)
        }
        setFiles(nf)
    }

    const onRem = (index) => {
        let nf = []
        files.forEach((f, i) => {
            if (i !== index) nf.push(f)
        })
        setFiles(nf)
    }

    const [country, setCountry] = useState('')
    const [zip, setZip] = useState('')
    const [region, setRegion] = useState('')
    const [district, setDistrict] = useState('')
    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [house, setHouse] = useState('')
    const [apartment, setApartment] = useState('')

    const [switchSomeAddress, setSwitchSomeAddress] = useState(false)

    useEffect(() => {
        if (country.length <= 3) {
            setReady(false)
            return
        }
        if (zip.length < 5 ||
            city.length <= 5 ||
            street.length < 8 ||
            house.length < 1
        ) {
            setReady(false)
            return
        }
        if (isReal) {
            setReady(true)
            return
        }
        let fileOk = false
        files
            .filter(f => f !== null)
            .forEach(
                f => {
                    fileOk = true
                }
            )
        setReady(fileOk)
    }, [files, country, zip, city, street, house])

    const sendReq = () => {
        setErrorMsg("")
        setLoading("Створюємо запит на зміну ваших даних...")
        const req = {
            fields: [
                {country}, {zip}, {region}, {district}, {city}, {street}, {house}, {apartment}
            ], name: "realAddress", hasFiles: false
        }
        if (!isReal) {
            req['fields'].push({someAddresses: switchSomeAddress})
            req['name'] = 'address'
            req['hasFiles'] = true
        }
        fetch("/api/v1/change",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req),
                redirect: "error"
            }
        )
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then(d => {
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setLoading("")
                    setTimeout(() => setErrorMsg(""), 8000)
                    return
                }
                if (d.token)
                    return d.token

                if (d.detail) {
                    setErrorMsg(d.detail)
                    if (d.title) {
                        setErrorTitle(d.title)
                    }
                    setTimeout(() => setErrorMsg(""), 8000)
                    setLoading("")
                    return
                }

                setErrorMsg("Виникла несподівана помилка під час обробки вашого запиту. Зверніться із запитом в підтримку.")
                setTimeout(() => setErrorMsg(""), 8000)
                setLoading("")
                return
            })
            .then(token => {
                if (!token) {
                    return
                }
                if (isReal) {
                    if (!errorMsg) {
                        Modal.getOrCreateInstance(document.querySelector('#addressChangeModal')).hide()

                        if (cb)
                            setTimeout(() => {
                                cb('Ваш запит успішно створено і очікує обробки адміністратора')
                            }, 500)

                    }
                }

                setLoading("Завантажуємо ваші файли...")
                files
                    .filter(f => f !== null)
                    .forEach((f, i) => {
                        setLoading("Завантажуємо ваші файли...")
                        let data = new FormData()
                        data.append('file', f.current.files[0])
                        data.append('token', token)
                        data.append('index', i)
                        fetch("/api/v1/change/upload", {
                            method: 'POST',
                            body: data,
                            redirect: "error"
                        })
                            .catch(reason => setErrorMsg(reason))
                            .then(r => r.json())
                            .then(d => {
                                setLoading("")
                                if (d.error) {
                                    setErrorMsg(d.error)
                                    return;
                                }
                                if (d.detail) {
                                    setErrorMsg(d.detail)
                                }
                            })
                    })
                if (!errorMsg) {
                    Modal.getOrCreateInstance(document.querySelector('#addressChangeModal')).hide()

                    if (cb)
                        setTimeout(() => {
                            cb('Ваш запит успішно створено і очікує обробки адміністратора')
                        }, 500)

                }
            })

    }
    let title = 'Зміна адреси реєстрації'
    let label1 = 'Поточна адреса реєстрації'
    let label2 = 'Нова адреса реєстрації'
    if (isReal) {
        title = 'Зміна адреси листування'
        label1 = 'Поточна адреса листування'
        label2 = 'Нова адреса листування'
    }
    return (<>
        <div className="modal fade" id="addressChangeModal" tabIndex="-1" aria-labelledby="addressChangeModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addressChangeModalLabel">{title}</h5>
                        {cb &&
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        }
                    </div>
                    <div className="modal-body">
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label fw-bold">{label1}</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input type="text" className="form-control"
                                                   readOnly={true} value={address.country}/>
                                        </div>
                                        <div className="col-md-4 pt-1 pt-md-0">
                                            <input type="text" className="form-control"
                                                   readOnly={true} value={address.zipCode}/>
                                        </div>
                                        <div className="col-md-4 pt-1 pt-md-0">
                                            <input type="text" className="form-control"
                                                   readOnly={true}
                                                   value={address.region ? address.region : "-"}/>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   readOnly={true}
                                                   value={address.district ? address.district : "-"}/>
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   readOnly={true} value={address.city}/>
                                        </div>

                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   readOnly={true} value={address.street}/>
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   readOnly={true}
                                                   value={address.house ? address.house : "-"}/>
                                        </div>
                                        <div className="col-10 col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   readOnly={true}
                                                   value={address.apartment ? address.apartment : "-"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label fw-bold">{label2}</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <select className="form-select" placeholder="Країна"
                                                    value={country}
                                                    onChange={event => setCountry(event.target.value)}>
                                                <option value="">Країна</option>
                                                <option value="Україна">Україна</option>
                                            </select>

                                        </div>
                                        <div className="col-md-4 pt-1 pt-md-0">
                                            <input type="text" className="form-control"
                                                   placeholder="Індекс"
                                                   value={zip}
                                                   onChange={event => setZip(event.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 pt-1 pt-md-0">
                                            <input type="text" className="form-control"
                                                   placeholder="Область"
                                                   value={region}
                                                   onChange={event => setRegion(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   placeholder="Район"
                                                   value={district}
                                                   onChange={event => setDistrict(event.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   placeholder="Місто/селище"
                                                   value={city}
                                                   onChange={event => setCity(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   placeholder="Вулиця"
                                                   value={street}
                                                   onChange={event => setStreet(event.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   placeholder="Будинок"
                                                   value={house}
                                                   onChange={event => setHouse(event.target.value)}
                                            />
                                        </div>
                                        <div className="col-10 col-md-4 pt-1">
                                            <input type="text" className="form-control"
                                                   placeholder="Квартира"
                                                   value={apartment}
                                                   onChange={event => setApartment(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isReal &&
                            <>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-4 pt-1">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                   checked={switchSomeAddress}
                                                   onChange={event => setSwitchSomeAddress(event.target.checked)}
                                            />
                                            <label className="form-check-label">
                                                Адреса листування співпадає з адресою реєстрації
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 ">
                                        <div className="mb-3">
                                            <label htmlFor="change_variant"
                                                   className="form-label fw-bold">Додатки</label>
                                            <p className={"form-text"}>Будь ласка, додайте копію сторінок паспорта з
                                                новою
                                                адресою реєстрації.</p>
                                            {files.map((f, i) => (
                                                <FileInput key={i} index={i} cb={onChangeFile} label={i === 0}
                                                           cbRem={i !== 0 ? onRem : null}/>))}

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                    <div className="modal-footer d-flex justify-content-center text-center">
                        <button type="button" className="btn btn-primary" disabled={!ready}
                                onClick={() => sendReq()}
                        >Змінити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}