import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import {Modal} from "bootstrap";

export default function FieldChange({value, name, cb}) {
    useEffect(() => {
        setTimeout(() => {
            let dm = document.querySelector('#fieldChangeModal')
            let m = Modal.getOrCreateInstance(dm)
            m.show()
            dm.addEventListener('hidden.bs.modal', function (e) {
                if (cb) {
                    cb()
                }
            })
        }, 10)

    }, [])
    const [ready, setReady] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [errorTitle, setErrorTitle] = useState("Помилка отримання даних!")
    const [loading, setLoading] = useState("")

    const [nValue, setNValue] = useState('')

    const sendReq = () => {
        setErrorMsg("")
        setLoading("Створюємо запит на зміну ваших даних...")
        const req = {
            fields: [
                {value: nValue}
            ], name: name, hasFiles: false
        }

        fetch("/api/v1/change",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req),
                redirect: "error"
            }
        )
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then(d => {
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setLoading("")
                    setTimeout(() => setErrorMsg(""), 8000)
                    return
                }
                if (d.token)
                    return d.token

                if (d.detail) {
                    setErrorMsg(d.detail)
                    if (d.title) {
                        setErrorTitle(d.title)
                    }
                    setLoading("")
                    setTimeout(() => setErrorMsg(""), 8000)
                    return
                }

                setErrorMsg("Виникла несподівана помилка під час обробки вашого запиту. Зверніться із запитом в підтримку.")
                setTimeout(() => setErrorMsg(""), 8000)
                setLoading("")
                return
            }).then(token => {
            if (!token) {
                return
            }

            if (!errorMsg) {
                Modal.getOrCreateInstance(document.querySelector('#fieldChangeModal')).hide()

                if (cb)
                    setTimeout(() => {
                        cb('Ваш запит успішно створено і очікує обробки адміністратора')
                    }, 500)

            }

        })
    }

    useEffect(() => {
        let regexp = /\\*/i;
        switch (name) {
            case 'email':
                regexp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                break;
            case 'phone':
                regexp = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/g;
                break;
        }
        if (regexp.exec(nValue) && nValue !== value) {
            setReady(true)
            return
        }
        setReady(false)
    }, [nValue])

    let title = ''
    let oldLabel = ''
    let nLabel = ''
    switch (name) {
        case 'email':
            title = 'Зміна Email'
            oldLabel = 'Поточна адреса Email'
            nLabel = 'Нова адреса Email'
            break
        case 'phone':
            title = 'Зміна мобільного телефону'
            oldLabel = 'Поточний номер'
            nLabel = 'Новий номер'
            break
    }


    return (<>
        <div className="modal fade" id="fieldChangeModal" tabIndex="-1" aria-labelledby="fieldChangeModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="fieldChangeModalLabel">{title}</h5>
                        {cb &&
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        }
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label fw-bold">{oldLabel}</label>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <input type="text" className="form-control"
                                                   value={value}
                                                   readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label fw-bold">{nLabel}</label>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <input type="text" className="form-control"
                                                   value={nValue}
                                                   onChange={event => setNValue(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                    <div className="modal-footer d-flex justify-content-center text-center">

                        <button type="button" className="btn btn-primary" disabled={!ready}
                                onClick={() => sendReq()}
                        >Змінити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}