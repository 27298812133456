import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import {Modal} from "bootstrap";


export default function IndexingRequest({contractId, cpiNow, cb}) {
    const [errorMsg, setErrorMsg] = useState("")
    const [errorTitle, setErrorTitle] = useState("Помилка отримання даних!")
    const [loading, setLoading] = useState("")
    const [resultTitle, setResultTitle] = useState("Чудове рішення!")
    const [resultMsg, setResultMsg] = useState("Запит щодо активації \"Індексації\" надіслано в страхову компанію. Про результати Вас буде повідомлено.")
    const [sendRequest, setSendRequest] = useState(false)

    useEffect(() => {
        if (!cpiNow) {
            setTimeout(() => {
                let dm = document.querySelector('#activateModal')
                let m = Modal.getOrCreateInstance(dm)
                m.show()
                dm.addEventListener('hidden.bs.modal', function (e) {
                    if (!sendRequest && cb) {
                        cb()
                    }
                })
            }, 10)
        }

        if (cpiNow) {
            setTimeout(() => {
                let dm = document.querySelector('#DisabledModal')
                let m = Modal.getOrCreateInstance(dm)
                m.show()
                dm.addEventListener('hidden.bs.modal', function (e) {
                    if (!sendRequest && cb) {
                        cb()
                    }
                })
            }, 10)
        }
    }, [])

    const sendRequestToInsurance = () => {
        setLoading("Виконуємо ваш запит...")
        const data = {contractId, isIndexed: !cpiNow}
        fetch("/api/v1/indexing",
            {
                redirect: "error",
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then(d => {
                setLoading("")
                if (d.detail) {
                    setErrorTitle(d.title === "bad request" ? "Помилка" : d.title)
                    setErrorMsg(d.detail)
                    setTimeout(() => setErrorMsg(""), 8000)
                    return
                }
                if (d.id) {
                    setSendRequest(true)
                    if (!cpiNow) {
                        setResultTitle("Чудове рішення!")
                        setResultMsg("Запит щодо активації \"Індексації\" надіслано в страхову компанію. Про результати Вас буде повідомлено.")
                        setTimeout(() => {
                            let dm = document.querySelector('#activateModal')
                            let m = Modal.getOrCreateInstance(dm)
                            m.hide()
                        }, 100)
                    }
                    if (cpiNow) {
                        setResultTitle("Запит надіслано.")
                        setResultMsg("Нагадуємо, що в подальшому, за бажанням, Ви зможете поновити опцію індексації.")
                        setTimeout(() => {
                            let dm = document.querySelector('#DisabledModal')
                            let m = Modal.getOrCreateInstance(dm)
                            m.hide()
                        }, 100)
                    }
                    setTimeout(() => {
                        let dm = document.querySelector('#resultModal')
                        let m = Modal.getOrCreateInstance(dm)
                        m.show()
                        dm.addEventListener('hidden.bs.modal', function (e) {
                            if (cb) {
                                cb("Ваш запит прийнято. Про результати Вас буде повідомлено.")
                            }
                        })
                    }, 400)
                }
            })
    }

    return (<>
        <div className="modal fade" id="resultModal" tabIndex="-1" aria-labelledby="resultModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        {/*<h5 className="modal-title" id="resultModalLabel">Підтвердження</h5>*/}
                        {cb &&
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        }
                    </div>
                    <div className="modal-body ">
                        <div className="text-center mb-2">
                            <h4 className="my-2">{resultTitle}</h4>
                            <p className="p-3">
                                {resultMsg}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="activateModal" tabIndex="-1" aria-labelledby="activateModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="activateModalLabel">Підтвердження</h5>
                        {cb &&
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        }
                    </div>
                    <div className="modal-body ">
                        <div className="d-flex justify-content-center text-center mb-2">
                            <p className="  w-75">
                                <strong>Індексація - </strong><br/> це забезпечення захисту реальної вартості договору
                                страхування від
                                інфляції за рахунок щорічного збільшення страхового внеску і, відповідно, страхових сум.
                            </p>
                        </div>

                        <div className="mt-5 mb-5 w-100 text-center">
                            <h4>Бажаєте активувати?</h4>
                            <div className="d-flex justify-content-center text-center">
                                <button type="button" className="btn btn-secondary w-25 me-4"
                                        data-bs-dismiss="modal">Ні
                                </button>
                                <button type="button" className="btn btn-success w-25"
                                        onClick={() => {
                                            sendRequestToInsurance()
                                        }}>Так
                                </button>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center text-center mt-4">
                            <p className="  w-75">
                                Натиснувши "Так", розмір страхового внеску за Вашим договором буде щорічно збільшуватись
                                на 6%.
                                Індексацію можна скасувати в подальшому.
                            </p>
                        </div>


                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="DisabledModal" tabIndex="-1" aria-labelledby="DisabledModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="DisabledModalLabel">Підтвердження</h5>
                        {cb &&
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        }
                    </div>
                    <div className="modal-body ">
                        <div className="d-flex justify-content-center text-center mb-2">
                            <p className="  w-75">
                                <strong>Індексація - </strong><br/> це забезпечення захисту реальної вартості договору
                                страхування від
                                інфляції за рахунок щорічного збільшення страхового внеску і, відповідно, страхових сум.
                            </p>
                        </div>

                        <div className="mt-5 mb-5 w-100 text-center">
                            <h4>Дійсно бажаєте відключити опцію?</h4>
                            <div className="d-flex justify-content-center text-center">
                                <button type="button" className="btn btn-secondary w-25 me-4"
                                        data-bs-dismiss="modal">Ні
                                </button>
                                <button type="button" className="btn btn-success w-25"
                                        onClick={() => {
                                            sendRequestToInsurance()
                                        }}>Так
                                </button>
                            </div>
                        </div>

                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)
}