import React, {useState} from "react";
import "./Files.scss";

export default function Files({files = [], url, cbRemove = null}) {
    const [collapsed, setCollapsed] = useState(true)

    if (files.length === 0) {
        return (<i>Файлів немає</i>)
    }

    if (collapsed) {
        let text = files.length + 'файлів'
        if (files.length === 1) {
            text = '1 файл'
        }
        if (files.length >= 2 && files.length < 5) {
            text = files.length + ' файла'
        }
        return (<>
            <div className="btn-group">
                <button type="button" className="btn btn-info" onClick={() => setCollapsed(false)}>{text}</button>
                <button type="button" className="btn btn-info dropdown-toggle dropdown-toggle-split"
                        onClick={() => setCollapsed(false)}
                        data-bs-toggle="dropdown" aria-expanded="false">
                </button>
            </div>
        </>)
    }

    if (!collapsed) {
        return (<>
            <div className="btn-group dropup mb-1">
                <button className="btn btn-secondary " onClick={() => {
                    setCollapsed(true)
                }}>Звернути</button>
                <button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                        onClick={() => setCollapsed(true)}
                        data-bs-toggle="dropdown" aria-expanded="false">
                </button>
            </div>
            <ul className="list-group list-group-flush">
                {files.map((d) => (
                    <li key={d.id} className="list-group-item">
                        <a href={`${url}/${d.id}`} title={d.description} target="_blank"> {d.name} </a>
                        {cbRemove &&
                            <button type="button" className="btn-close" aria-label="Close"
                                    onClick={() => cbRemove(d.id)}></button>}
                    </li>
                ))}
            </ul>
        </>)
    }

}