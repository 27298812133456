import React, {useEffect, useState} from "react";
import ConsultantList from "./ConsultantList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import WrapperFetch from "../../srv_tools/WrapperFetch";

export default function Header({showConsultant=true}){
    const [user, setUser] =useState({"clName": false})
    useEffect(()=>{
        WrapperFetch.getInstance().enqueue("/api/v1/whoami", {redirect: "error"}, u=>setUser(u), e=>window.location.replace(process.env.REACT_APP_LOGIN_URL));
    },[])
    useEffect(()=>{
        if(user.clName)
        window.localStorage['user'] = JSON.stringify(user)
        if(!user.clName)
            window.localStorage['user'] = "{}"
    }, [user])
    return (
            <header >
                <div className={"row justify-content-between"}>
                    <div className={"col-md-4 mx-md-5"}>
                        {showConsultant &&
                            <ConsultantList/>
                        }
                    </div>
                    <div className={"col-md-4"}>
                        <div className="row p-4">
                            <div className="col-9 text-end">
                                Вітаємо <br/>
                                <span className={"fw-bold"}>{user.clName}</span>
                            </div>
                            <div className="col-md-3 pe-3 text-end">
                                <a href={process.env.REACT_APP_LOGOUT_URL} title={"Вихід"} className={"text-black"} onClick={()=>{window.localStorage['user']='{}'}}><FontAwesomeIcon icon={solid("arrow-right-from-bracket")} size={"3x"}/></a>
                            </div>
                        </div>

                    </div>

                </div>
            </header>
    )
}