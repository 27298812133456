import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import {Link} from "react-router-dom";
import Cell from "./Cell";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function Total() {
    const [arrContracts, setArrContracts] = useState([])
    const [arrYears, setArrYears] = useState([])
    const [data, setData] = useState({})

    const [errorMsg, setErrorMsg] = useState("")

    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/calendar", {redirect: "error"},
            d => {
                setArrContracts(d.contracts)
                setArrYears(d.indexRows)
                setData(d.data)
            },
            reason => {
            setErrorMsg(reason.message)
        })
    }, [])

    return (
        <div className={"my-4"}>
            {errorMsg &&
                <Alert header={"Помилка отримання даних!"} type={"danger"}>
                    {errorMsg}
                </Alert>
            }
            <div className="table-responsive fix-header-table">
                <table className="table ">
                    <thead >
                    <tr className={"text-center"}>
                        <th></th>
                        {arrContracts.map((c) => (<th key={c.id}>
                            <Link to={`/contracts/${c.id}`}
                                  className={"text-black text-decoration-none"}>{c.id}</Link>

                        </th>))}
                    </tr>
                    </thead>
                    <tbody className={"text-center"}>
                    {arrYears.map((y) => (<tr key={y}>
                        <th>{y}</th>
                        {arrContracts.map((c) => (<Cell key={c.id} year={y} contract={c} inpays={data[c.id]}/>))}
                    </tr>))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}