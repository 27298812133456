import React, {useEffect, useState} from "react";
import Header from "../../header/Header";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from "../../common/Alert";
import FioChange from "./FioChange";
import PassportChange from "./PassportChange";
import AddressChange from "./AddressChange";
import FieldChange from "./FieldChange";
import WrapperFetch from "../../../srv_tools/WrapperFetch";


export default function Profile() {
    const [profile, setProfile] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [msg, setMsg] = useState("")

    const [modeFio, setModeFio] = useState(false)
    const [modePassport, setModePassport] = useState(false)
    const [modeAddress, setModeAddress] = useState(false)
    const [modeRealAddress, setModeRealAddress] = useState(false)
    const [modeEmail, setModeEmail] = useState(false)
    const [modePhone, setModePhone] = useState(false)

    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/my_profile", {redirect: "error"},
                p => setProfile(p),
                reason => setErrorMsg(reason));
    }, [])

    useEffect(() => {
        if (msg) {
            setTimeout(() => {
                setMsg("")
            }, 8000)
        }
    }, [msg])
    return (
        <>
            <div className="row mx-2">
                <div className="col-md-12 p-2 page-header">
                    <Header/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-md-12 p-3 px-5 page-block-min">
                    <div className="my-3">
                        <h4 className={"text-black-50 text-uppercase mb-3"}>Мій профіль</h4>
                        {!profile && !errorMsg &&
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-success " role="status">
                                    <span className="visually-hidden">Завантаження...</span>
                                </div>
                            </div>
                        }

                        {errorMsg &&
                            <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {msg &&
                            <Alert header={"Результат обробки запиту"} type={"success"} addClass={"my-4"}>
                                {msg}
                            </Alert>
                        }
                        {profile && <>

                            <div className="row">
                                <div className="col-md-9">
                                    <div className="mb-3">
                                        <label htmlFor="client_fio" className="form-label fw-bold">Страхувальник</label>
                                        <div className="row">
                                            <div className="col-10 col-md-10">
                                                <input type="text" className="form-control" id="client_fio"
                                                       value={(profile.surname + " " + profile.firstname + " " + profile.fathername)}
                                                       readOnly={true}/>
                                            </div>
                                            <div className="col-2 col-md-1">
                                                <button className="btn btn-light" onClick={() => setModeFio(true)} title="Змінити ПІБ">
                                                    <FontAwesomeIcon
                                                        icon={solid("pencil")}
                                                        size={"1x"}/></button>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label htmlFor="bd_date" className="form-label fw-bold">Дата народження</label>
                                        <input type="text" className="form-control" id="bd_date" disabled={true}
                                               value={profile.birthday}/>

                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="passport_sn" className="form-label fw-bold">Паспорт</label>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <input type="text" className="form-control" id="passport_sn"
                                                   disabled={true} value={profile.passport.sn}/>
                                        </div>
                                        <div className="col-md-7 pt-1 pt-md-0">
                                            <input type="text" className="form-control" id="passport_src"
                                                   readOnly={true} value={profile.passport.issuer}/>
                                        </div>
                                        <div className="col-10 col-md-2 pt-1 pt-md-0">
                                            <input type="text" className="form-control" id="passport_date"
                                                   readOnly={true} value={profile.passport.date}/>
                                        </div>
                                        <div className="col-2 col-md-1 pt-1 pt-md-0">
                                            <button className="btn btn-light" onClick={() => setModePassport(true)} title="Змінити паспортні дані">
                                                <FontAwesomeIcon icon={solid("pencil")}
                                                                 size={"1x"}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="inn" className="form-label fw-bold">Ідентифікаційний
                                            код</label>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <input type="text" className="form-control" id="inn"
                                                       readOnly={true} value={profile.taxID}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="bd_date" className="form-label fw-bold">Email</label>
                                        <div className="row">
                                            <div className="col-10 col-md-9">
                                                <input type="text" className="form-control" id="bd_date"
                                                       disabled={true} value={profile.email}/>
                                            </div>
                                            <div className="col-2 col-md-1">
                                                <button className="btn btn-light" onClick={() => setModeEmail(true)} title="Змінити email">
                                                    <FontAwesomeIcon
                                                        icon={solid("pencil")}
                                                        size={"1x"}/></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label fw-bold">Мобільний
                                            телефон</label>
                                        <div className="row">
                                            <div className="col-10 col-md-9">
                                                <input type="text" className="form-control" id="phone"
                                                       readOnly={true} value={profile.phoneMob}/>
                                            </div>
                                            <div className="col-2 col-md-1">
                                                <button className="btn btn-light" onClick={()=>setModePhone(true)} title="Змінити номер телефону">
                                                    <FontAwesomeIcon
                                                    icon={solid("pencil")}
                                                    size={"1x"}/></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <label htmlFor="address_country" className="form-label fw-bold">Адреса
                                        реєстрації</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" id="address_country"
                                                   readOnly={true} value={profile.address.country}/>
                                        </div>
                                        <div className="col-md-4 pt-1 pt-md-0">
                                            <input type="text" className="form-control" id="address_zip"
                                                   readOnly={true} value={profile.address.zipCode}/>
                                        </div>
                                        <div className="col-md-3 pt-1 pt-md-0">
                                            <input type="text" className="form-control" id="address_region"
                                                   readOnly={true}
                                                   value={profile.address.region ? profile.address.region : "-"}/>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="address_des"
                                                   readOnly={true}
                                                   value={profile.address.district ? profile.address.district : "-"}/>
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="address_city"
                                                   readOnly={true} value={profile.address.city}/>
                                        </div>

                                    </div>
                                    <div className="row ">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="address_street"
                                                   readOnly={true} value={profile.address.street}/>
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="address_house"
                                                   readOnly={true}
                                                   value={profile.address.house ? profile.address.house : "-"}/>
                                        </div>
                                        <div className="col-10 col-md-3 pt-1">
                                            <input type="text" className="form-control" id="address_apartment"
                                                   readOnly={true}
                                                   value={profile.address.apartment ? profile.address.apartment : "-"}/>
                                        </div>
                                        <div className="col-2 col-md-1 pt-1">
                                            <button className="btn btn-light" onClick={() => setModeAddress(true)} title="Змінити адресу реєстрації">
                                                <FontAwesomeIcon icon={solid("pencil")} size={"1x"}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*apartment: null*/}
                            {/*city: "м. Дніпро"*/}
                            {/*country: "Україна"*/}
                            {/*district: null*/}
                            {/*house: "буд. 19"*/}
                            {/*passport: "АМ 263450 вид. Дніпропетровським РВУМВС України в Дніпропетровській обл. від 22.02.2001 р."*/}
                            {/*region: null*/}
                            {/*street: "вул. Івана Пулюя"*/}
                            {/*zipCode: "49026"*/}

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <label htmlFor="reg_country" className="form-label fw-bold">Адреса
                                        листування</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" id="reg_country"
                                                   readOnly={true} value={profile.realAddress.country}/>
                                        </div>
                                        <div className="col-md-4 pt-1 pt-md-0">
                                            <input type="text" className="form-control" id="reg_zip"
                                                   readOnly={true} value={profile.realAddress.zipCode}/>
                                        </div>
                                        <div className="col-md-3 pt-1 pt-md-0">
                                            <input type="text" className="form-control" id="reg_region"
                                                   readOnly={true}
                                                   value={profile.realAddress.region ? profile.realAddress.region : "-"}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="reg_des"
                                                   readOnly={true}
                                                   value={profile.realAddress.district ? profile.realAddress.district : "-"}/>
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="reg_city"
                                                   readOnly={true} value={profile.realAddress.city}/>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="reg_street"
                                                   readOnly={true} value={profile.realAddress.street}/>
                                        </div>
                                        <div className="col-md-4 pt-1">
                                            <input type="text" className="form-control" id="reg_house"
                                                   readOnly={true}
                                                   value={profile.realAddress.house ? profile.realAddress.house : "-"}/>
                                        </div>
                                        <div className="col-10 col-md-3 pt-1">
                                            <input type="text" className="form-control" id="reg_apartment"
                                                   readOnly={true}
                                                   value={profile.realAddress.apartment ? profile.realAddress.apartment : "-"}/>
                                        </div>
                                        <div className="col-2 col-md-1 pt-1">
                                            <button className="btn btn-light" onClick={() => setModeRealAddress(true)} title="Змінити адресу листування">
                                                <FontAwesomeIcon
                                                    icon={solid("pencil")}
                                                    size={"1x"}/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            {modeFio &&
                <FioChange surname={profile.surname} firstname={profile.firstname} fathername={profile.fathername}
                           cb={(m) => {
                               setMsg(m);
                               setModeFio(false)
                           }}/>}

            {modePassport &&
                <PassportChange sn={profile.passport.sn} issuer={profile.passport.issuer} date={profile.passport.date}
                                cb={(m) => {
                                    setModePassport(false);
                                    setMsg(m)
                                }}/>}

            {modeAddress &&
                <AddressChange address={profile.address} cb={(m) => {
                    setModeAddress(false);
                    setMsg(m)
                }}/>
            }
            {modeRealAddress &&
                <AddressChange address={profile.realAddress} isReal={true} cb={(m) => {
                    setModeRealAddress(false);
                    setMsg(m)
                }}/>
            }
            {modeEmail &&
                <FieldChange value={profile.email} name="email" cb={(m) => {
                    setModeEmail(false);
                    setMsg(m)
                }}/>
            }
            {modePhone &&
                <FieldChange value={profile.phoneMob} name="phone" cb={(m) => {
                    setModePhone(false);
                    setMsg(m)
                }}/>
            }
        </>
    )
}




