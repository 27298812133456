import React from "react";
import Header from "../../header/Header";

export default function Docs(){
    return (
        <div style={{width: "100%"}}>
            <div className="row mx-2">
                <div className="col-12 p-2 page-header">
                    <Header showConsultant={true}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-12 p-2 px-5 page-block-min">
                    <div className="my-3">
                        <h4 className={"text-black-50"}>Документи</h4>
                        Розділ в розробці

                    </div>
                </div>

            </div>
        </div>
    )
}