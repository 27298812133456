import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import AsyncSelect from "react-select/async";
import Alert from "../../../common/Alert";

export default function SelectClient({resultHandle}){
    const [selectState, setSelectState] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [errorMsg, setErrorMsg] = useState("")
    const [isFinished, setIsFinished] = useState(false)

    const btnHtml = useRef(null)

    const finish =function (){
        if(resultHandle)resultHandle(selectedOption)
        setIsFinished(true)
    }

    useEffect(() => {
        if (selectState) {
            btnHtml.current.focus();
        }
    }, [selectState])

    const loadOptions = function (inputValue){
        const page=0
        const sizePage=30
        return fetch(`/admin/api/v1/clients?page=${page}&size=${sizePage}&clName=${inputValue}`, {redirect: "error"})
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then(d=>d._embedded && d._embedded.clients ? d._embedded.clients : [])
            .then(d=>d.map((v)=>{return { value: v.clientId, label: v.clName }}))
    }
    if(!selectState){
        return (
            <>
                <button className={"btn btn-primary"} onClick={()=>setSelectState(true)} title="Обрати клієнта для привʼязки до користувача"
                ><FontAwesomeIcon icon={solid("plus")}/></button>
            </>
        )
    }
    if(isFinished){
        return (
                <div className="spinner-border text-success " role="status">
                    <span className="visually-hidden">Завантаження...</span>
                </div>
        )
    }
    return (
        <div className={"row"}>
            <div className="col-8">
                <AsyncSelect ref={btnHtml}
                             onKeyDown={event => setSelectState(event.code !== "Escape")}
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    defaultOptions
                    loadOptions={loadOptions}
                />

            </div>
            <div className="col-4">
                <button  className={"btn btn-success"} onClick={()=>{finish()}} >
                    <FontAwesomeIcon icon={solid("check")}/>
                </button>
                <button className={"btn btn-light ms-1"} onClick={()=>{setSelectState(false)}}  title="Відміна">
                    <FontAwesomeIcon icon={solid("close")}/>
                </button>
            </div>
            {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                {errorMsg}
            </Alert>}
        </div>
    )
}