import React, {useEffect, useState} from "react";


export default function Alert({children, type = "success", header = false, footer = false, hide = 0, dismiss = true, addClass=""}) {
    const [show, setShow] = useState(true)

    useEffect(() => {
        if (hide > 0)
            setTimeout(() => {
                setShow(false)
            }, hide)
    }, [hide])

    const cl = "alert alert-" + type + " alert-dismissible fade show " + addClass
    return (
        <>
            {show &&
                <div className={cl}>
                    {header && <h4 className={"alert-heading"}>{header}</h4>}
                    {dismiss &&
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"
                                onClick={() => setShow(false)}></button>
                    }
                    {children}

                    {footer &&
                        <>
                            <hr/>
                            {footer}
                        </>
                    }
                </div>
            }
        </>

    )
}