import React, {useEffect, useState} from "react";
import Header from "../../../header/Header";
import Alert from "../../../common/Alert";
import CrudNav from "../common/CrudNav";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "bootstrap";
import FileInput from "../../profile/FileInput";
import Files from "../../../common/Files";
import WrapperFetch from "../../../../srv_tools/WrapperFetch";

export default function InsuredEvents() {
    const [data, setData] = useState([])
    const [errorTitle, setErrorTitle] = useState("Помилка отримання даних!")
    const [errorMsg, setErrorMsg] = useState("")
    const [msg, setMsg] = useState("")
    const [loading, setLoading] = useState(true)

    const [fStatus, setFStatus] = useState("")
    const [page, setPage] = useState({number: 0})
    const [size, setSize] = useState(20)

    const [descriptions, setDescriptions] = useState([""])
    const [files, setFiles] = useState([null])
    const [eventId, setEventId] = useState(null)
    const [docs, setDocs] = useState({})

    const [status, setStatus] = useState("")
    const [amount, setAmount] = useState(0)
    const [currency, setCurrency] = useState("")
    const [adminMessage, setAdminMessage] = useState("")

    //region load data
    const getData = function (p) {
        setLoading(true)
        let st = fStatus === '-' ? '' : fStatus
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/tools/insured_events?page=${p}&size=${size}&status=${st}`,
            {redirect: "error"},
            d => {
                setLoading(false)
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setData([])
                    return
                }
                if (d.page)
                    setPage(d.page);
                setData(d._embedded && d._embedded.insuredEvents ? d._embedded.insuredEvents : [])
            },
            reason => setErrorMsg(reason));
    }

    const timeReform = function (d) {
        //2022-05-21T19:11:07.000+00:00
        if (!d) return;
        return d.split('.')[0].split('T').join(' ')
    }
    useEffect(() => {
        if (!(fStatus)) return;
        getData(page.number)
    }, [fStatus])


    useEffect(() => {
        let ids = data.map((ie) => ie.ieId).join(",")
        if (ids.length === 0) return
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/tools/insured_events/files?ie_ids=${ids}`, {redirect: "error"},
            d => {
                setDocs(d)
            },
            reason => setErrorMsg(reason));
    }, [data])
    //endregion

    //region processing
    const onChangeFile = (index, name, fileRef) => {
        let nf = []
        files.forEach(f => nf.push(f))
        nf[index] = fileRef
        if (name !== null && nf.length <= index + 1) {
            nf.push(null)
        }
        setFiles(nf)
        setDescriptions((d) => {
            if (d.length < nf.length) d.push("");
            return d
        })
    }

    const onRem = (index) => {
        let nf = []
        let desc = []
        files.forEach((f, i) => {
            if (i !== index) {
                nf.push(f)
                desc.push(descriptions[i])
            }
        })
        setFiles(nf)
        setDescriptions(desc)
    }

    const modalUpload = (eId) => {
        setEventId(eId)
        let mdiv = document.querySelector("#docsUploadingModal")
        Modal.getOrCreateInstance(mdiv).show()
        mdiv.addEventListener("hide.bs.modal", () => {
            setEventId(null)
            setFiles([null])
            setDescriptions([""])
        })
    }
    const deleteFile = (id) => {
        if (window.confirm("Ви впевнені, що хочете видалити файл?")) {
            setLoading(true)
            fetch(`/admin/api/v1/tools/insured_events/files/${id}`, {
                method: 'DELETE',
                redirect: "error"
            }).then(r => {
                    if (r.status === 200) {
                        setLoading(false)
                        setMsg("Файл успішно видалено")
                        setTimeout(() => {
                            setMsg("")
                        }, 5000)
                        getData(page.number)
                        return
                    }
                    return r.json()
                }
            )
                .then(d => {
                    if (d.error) {
                        setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                        return
                    }

                    getData(page.number)
                })
        }
    }

    const uploading = () => {
        files
            .filter(f => f !== null)
            .forEach((f, i) => {
                setLoading("Завантажуємо ваші файли...")
                let data = new FormData()
                data.append('file', f.current.files[0])
                data.append('id', eventId)
                data.append('description', descriptions[i])
                fetch("/admin/api/v1/tools/insured_events/files", {
                    method: 'POST',
                    body: data,
                    redirect: "error"
                })
                    .catch(reason => setErrorMsg(reason))
                    .then(r => {
                        if (r.status === 200) {
                            setLoading(false)
                            setMsg("Файли успішно завантажені")
                            setTimeout(() => {
                                setMsg("")
                            }, 5000)

                            return
                        }
                        return r.json()
                    })
                    .then(d => {
                        setLoading(false)
                        if (d.error) {
                            if (d.message) {
                                setErrorMsg(d.message)
                                return;
                            }
                            setErrorMsg(d.error)
                            return;
                        }
                        if (d.detail) {
                            setErrorMsg(d.detail)
                            return;
                        }

                    })
            })
        if (!errorMsg) {
            getData(page.number)
            Modal.getOrCreateInstance(document.querySelector('#docsUploadingModal')).hide()
            setTimeout(() => {
                setErrorMsg("")
            }, 10000)
        }

    }

    const modalEdit = (ieId) => {
        setEventId(ieId)
        data.forEach(ie => {
            if (ie.ieId === ieId) {
                setStatus(ie.status)
                setAmount(ie.amount)
                setCurrency(ie.currency)
                setAdminMessage(ie.adminMessage)
            }
        })
        let mdiv = document.querySelector("#editIEModal")
        Modal.getOrCreateInstance(mdiv).show()
        mdiv.addEventListener("hide.bs.modal", () => {
                setEventId(null)
            }
        )
    }

    const edit = () => {
        setLoading(true)
        fetch(`/admin/api/v1/tools/insured_events`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: eventId,
                status: status,
                payout: amount,
                currency: currency,
                message: adminMessage
            }),
            redirect: "error"
        }).catch(reason => setErrorMsg(reason))
            .then(r => {
                    finishEdit()
                    if (r.status === 200) {
                        setMsg("Дані успішно оновлено")
                        setTimeout(() => {
                            setMsg("")
                        }, 5000)
                        getData(page.number)
                        return
                    }
                    return r.json()
                }
            )
            .then(d => {
                if (d.error) {
                    if (d.message) {
                        setErrorMsg(d.message)
                        setTimeout(() => {
                            setErrorMsg("")
                        }, 5000)
                        return;
                    }
                    setErrorMsg(d.error)
                    return;
                }
                if (d.detail) {
                    setErrorMsg(d.detail)
                    return;
                }
            })
    }

    const finishEdit = () => {
        let mdiv = document.querySelector("#editIEModal")
        Modal.getOrCreateInstance(mdiv).hide()
        setLoading(false)
    }
    //endregion

    //region view
    function convertStatus(status) {
        switch (status) {
            case "WAITING":
                return "Очікуємо документи"
            case "PAYED":
                return "Виплачено"
            case "REFUSAL":
                return "Відмова"
            case "PENDING":
                return "На розгляді"
        }
    }

    //endregion

    return (<>
        <div className="row mx-2">
            <div className="col-12 p-2 page-header">
                <Header showConsultant={false}/>
            </div>
        </div>
        <div className="row g-0 mx-2 my-2">
            <div className="col-12 p-2 px-5 page-block-min">
                <div className="my-3">
                    <h4 className={"text-black-50"}>Страхові випадки і виплати</h4>
                    <div className={"border p-3"}>
                        {/*form*/}
                        <form onSubmit={(e) => e.preventDefault()}>
                            <h5>Фільтр</h5>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <label htmlFor="search_by_name"
                                               className="col-sm-4 col-form-label">Статус</label>
                                        <div className="col-sm-8">
                                            <select id="search_by_name" value={fStatus}
                                                    onChange={event => setFStatus(event.target.value)}
                                                    className="form-control">
                                                <option value="-">Всі</option>
                                                <option value="WAITING">Очікуємо документи</option>
                                                <option value="PENDING">На розгляді</option>
                                                <option value="PAYED">Виплачено</option>
                                                <option value="REFUSAL">Відмова</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    {/*<button className={"btn btn-success"} onClick={() => getClients(page.number)}>*/}
                                    {/*    <FontAwesomeIcon*/}
                                    {/*        icon={solid("search")}/>*/}
                                    {/*</button>*/}
                                </div>
                                <div className="offset-sm-2 col-sm-4">
                                    <div className="row">
                                        <label htmlFor="row_on_page" className={"col-sm-8 col-form-label text-end"}>Зап.
                                            на
                                            ст.</label>
                                        <div className="col-sm-4">
                                            <select id="row_on_page" value={size}
                                                    onChange={(e) => setSize(e.target.value)}
                                                    className="form-control">
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                        {errorMsg}
                    </Alert>}
                    {msg && <Alert type={"success"} addClass={"my-4"}>
                        {msg}
                    </Alert>}
                    {/*table*/}
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>Користувач /<br/> Застрахована особа</th>
                                <th>Дата повідомлення</th>
                                <th>Повідомлення</th>
                                <th>Номер договору</th>
                                <th>Ризик</th>
                                <th>Статус</th>
                                <th>Документи</th>
                                <th>Сума виплати</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((e) => (<tr key={e.ieId}>
                                <td><strong>Користувач</strong><br/>{e.userPhone}<br/>
                                    <strong>Заст.особа</strong><br/>{e.name}<br/>
                                    <strong><FontAwesomeIcon icon={solid("phone")}/></strong> {e.phone}<br/>
                                    <strong><FontAwesomeIcon icon={solid("mail-bulk")}/></strong> {e.email}</td>
                                <td>{timeReform(e.created)}</td>
                                <td>{e.message}</td>
                                <td>{e.contract}</td>
                                <td></td>
                                <td>{convertStatus(e.status)} {e.status === "PAYED" && e.payed && <>
                                    <br/>{timeReform(e.payed)}</>}
                                    {e.status === "REFUSAL" && e.refusal && <><br/>{timeReform(e.refusal)}</>}
                                    {e.status === "REFUSAL" && e.adminMessage !== "" && <><br/>{e.adminMessage}</>}
                                </td>
                                <td>
                                    {docs[e.ieId] && <Files files={docs[e.ieId]} cbRemove={deleteFile}
                                                            url={'/admin/api/v1/tools/insured_events/files'}/>}
                                </td>
                                <th>{e.payout && e.currency && <>{e.payout} {e.currency}</>}</th>
                                <td>
                                    <button className="btn btn-info m-1" title="Завантажити файли"
                                            onClick={() => modalUpload(e.ieId)}>
                                        <FontAwesomeIcon icon={solid("upload")}/>
                                    </button>
                                    <button className="btn btn-warning m-1 " title="Редагувати"
                                            onClick={() => modalEdit(e.ieId)}>
                                        <FontAwesomeIcon icon={solid("edit")}></FontAwesomeIcon>
                                    </button>
                                </td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                    {/*navigation under table*/}
                    <CrudNav page={page} getData={getData} size={size}/>

                    {loading && <div className="d-flex justify-content-center">
                        <div className="spinner-border text-success " role="status">
                            <span className="visually-hidden">Завантаження...</span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <div className="modal fade" id="docsUploadingModal" tabIndex="-1" aria-labelledby="docsUploadingModalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="docsUploadingModalLabel">Завантаження документів</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>Оберіть файли для завантаження в якості документів для обраної страхової події:</p>
                        <div className="row mt-2">
                            <div className="col-12 ">
                                <div className="mb-3">
                                    {files.map((f, i) => (<div key={i}>
                                        <label className="form-label mt-3">Опис файлу</label>
                                        <textarea className="form-control mb-3" rows="2" onChange={event => {
                                            let td = [...descriptions]
                                            td[i] = event.target.value;
                                            setDescriptions(td);
                                        }} value={descriptions[i]}/>
                                        <FileInput index={i} cb={onChangeFile} label={i === 0}
                                                   cbRem={i !== 0 ? onRem : null}/>
                                    </div>))}

                                </div>
                            </div>
                        </div>

                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                    <div className="modal-footer d-flex justify-content-center text-center">
                        <button type="button" className="btn btn-primary"
                                onClick={() => uploading()}>Завантажити
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="editIEModal" tabIndex="-1" aria-labelledby="editIEModalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editIEModalLabel">Редагування страхового випадку</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Встановити статус</label>
                                    <select className="form-control" onChange={event => setStatus(event.target.value)}
                                            value={status}>
                                        <option value="">Виберіть статус</option>
                                        <option value="WAITING">Очікуємо документи</option>
                                        <option value="PAYED">Виплачено</option>
                                        <option value="REFUSAL">Відмова</option>
                                        <option value="PENDING">На розгляді</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        {status === "REFUSAL" && <div className="row my-3">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Повідомлення для відмови (Буде відображатися для
                                        клієнта)</label>
                                    <textarea className="form-control" rows="3"
                                              onChange={event => setAdminMessage(event.target.value)}
                                              value={adminMessage}/>

                                </div>
                            </div>
                        </div>}

                        <div className="row mt-2">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label">Сума виплати</label>
                                    <input type="number" className="form-control"
                                           onChange={event => setAmount(event.target.value)}
                                           value={amount}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-label">Валюта виплати</label>
                                    <select className="form-control" onChange={event => setCurrency(event.target.value)}
                                            value={currency}>
                                        <option value="">Виберіть валюту</option>
                                        <option value="UAH">Гривня</option>
                                        <option value="USD">Долар</option>
                                        <option value="EUR">Євро</option>
                                        <option value="RUR">Rur</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {errorMsg &&
                            <Alert header={errorTitle} type={"danger"} addClass={"my-4"}>
                                {errorMsg}
                            </Alert>
                        }
                        {loading &&
                            <Alert dismiss={false} type={"success"} addClass={"my-4"}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-center">
                                        {loading}
                                    </div>
                                    <div className="col-4 d-flex justify-content-center">
                                        <div className="spinner-border text-success " role="status">
                                            <span className="visually-hidden">Завантаження...</span>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        }
                    </div>
                    <div className="modal-footer d-flex justify-content-center text-center">
                        <button type="button" className="btn btn-primary"
                                onClick={() => edit()}>Зберегти
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}