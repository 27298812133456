import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import Participant from "./Participant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function Participants({contract}){
    const [client, setClient] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")

    const [issued, setIssued] = useState(null)
    const [benefits, setBenefits] = useState([])

    useEffect(()=>{
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contract.id}/participant/client`, {redirect: "error"},
            c => setClient(c),
            reason => setErrorMsg(reason))
    }, [contract.id])
    useEffect(()=>{
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contract.id}/participant/issued`, {redirect: "error"},
            c => setIssued(c),
            reason => setErrorMsg(reason))
    }, [contract.id])

    useEffect(()=>{
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contract.id}/beneficiaries`, {redirect: "error"},
            p => setBenefits(p._embedded.benefitViews),
            reason => setErrorMsg(reason))
    }, [contract.id])
    if(contract.error)return (<></>);
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <h4>{contract.printId} <span className={"text-black-50"}>{contract.prgIns.name}</span></h4>
                </div>
                <div className="col-md-2">
                    <span className={"badge rounded-pill " + (contract.status.isClose ? "bg-secondary": "bg-success")}>{contract.status.statusName}</span>
                </div>
            </div>
            {errorMsg &&
                <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                    {errorMsg}
                </Alert>
            }
            <div className="my-4">
                <h4 className={"text-uppercase"}>Страхувальник</h4>
                {client &&<Participant participant={client} />}
                <button className={"btn btn-danger text-uppercase my-2 rounded-pill"} data-bs-toggle="modal" data-bs-target="#change_modal_client">Змінити Срахувальника</button>
            </div>

            <div className={"my-5"}>
                <h4 className="text-uppercase">Застрахована особа</h4>
                {issued && <Participant participant={issued} /> }
            </div>

            <div>
                <h4 className={"text-uppercase"}>Вигодонабувачі</h4>
                {benefits.map((p)=>(<div key={p.participant ? p.participant.personId+"_"+p.eventName : (p.client ? p.client.clientId+"_"+p.eventName : p.real +"_"+p.eventName)} className={"my-3"}>
                    <Participant participant={p.participant} event={p.eventName} percent={p.percent} client={p.client} is_real={p.real} />
                </div>))}
                <button className={"btn btn-danger text-uppercase my-2 rounded-pill"} data-bs-toggle="modal" data-bs-target="#change_modal_benefits">Змінити Вигодонабувачів</button>
            </div>



            <div className="modal fade" id="change_modal_client" tabIndex="-1"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="change_modal_clientLabel">Внести зміни</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <p>Для зміни Страхувальника, будь ласка, заповніть заяву, що додається, та надішліть на адресу:</p>

                            <p className={"text-black"}><strong>Укрпоштою:</strong> ТДВ «СК «ГЛІ»,<br/>вул. Кирилівська, 160-Б, оф. 40, 04073, м. Київ.</p>

                            <p className={"text-black"}><strong>Новою поштою:</strong> м. Київ Поштомат № 35065<br/> (вул. Кирилівська, 160-Б (хлібна кава)), <br/>(Дмитрик Ірина, +38 050 222 32 81).</p>
                            <hr/>

                            <p><a className={"text-danger text-decoration-none"} href="https://gli.com.ua/wp-content/uploads/2022/07/Zmina-Strahuvalnyka.pdf" target={"_blank"} rel="noreferrer"><FontAwesomeIcon icon={solid("file-pdf")} size={"3x"} /> Заява на внесення змін до Договру</a></p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="change_modal_benefits" tabIndex="-1"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="change_modal_benefitsLabel">Внести зміни</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <p>Для зміни Вигодонабувачів, будь ласка, заповніть заяву, що додається, та надішліть на адресу:</p>

                            <p className={"text-black"}><strong>Укрпоштою:</strong> ТДВ «СК «ГЛІ»,<br/>вул. Кирилівська, 160-Б, оф. 40, 04073, м. Київ.</p>

                            <p className={"text-black"}><strong>Новою поштою:</strong> м. Київ Поштомат № 35065<br/> (вул. Кирилівська, 160-Б (хлібна кава)), <br/>(Дмитрик Ірина, +38 050 222 32 81).</p>
                            <hr/>
                            <p><a className={"text-danger text-decoration-none"}
                                  href="https://gli.com.ua/wp-content/uploads/2022/07/Vygodonabuvachi.pdf"
                                  target={"_blank"} rel="noreferrer">
                                <FontAwesomeIcon icon={solid("file-pdf")} size={"3x"} /> Заява на змін Вигодонабувачів</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}