import React, {useEffect, useState} from "react";
import Header from "../../../header/Header";
import Alert from "../../../common/Alert";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Req4ChangeControl.scss";
import {Modal} from "bootstrap";
import CrudNav from "../common/CrudNav";
import WrapperFetch from "../../../../srv_tools/WrapperFetch";

export default function Req4ChangeControl({toggleUpdater}) {
    //region all states
    const [data, setData] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(true)
    const [fStatus, setFStatus] = useState("NEW")

    const [page, setPage] = useState({number: 0})
    const [size, setSize] = useState(20)

    //endregion

    //region load data
    const getData = function (p) {
        setLoading(true)
        let st = fStatus === '-' ? '' : fStatus
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/tools/req4change?page=${p}&size=${size}&status=${st}`,
            {redirect: "error"},
            d => {
                setLoading(false)
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setData([])
                    return
                }
                if (d.page)
                    setPage(d.page);
                setData(d._embedded && d._embedded.req4Changes ? d._embedded.req4Changes : [])
            },
            reason => setErrorMsg(reason))
    }

    const timeReform = function (d) {
        //2022-05-21T19:11:07.000+00:00
        if (!d) return;
        return d.split('.')[0].split('T').join(' ')
    }

    useEffect(() => {
        if (!(fStatus)) return;
        getData(page.number)
    }, [fStatus])


    //endregion


    //region view functions
    const classTr = (status) => {
        switch (status) {
            case "NEW":
                return "table-danger"
            case "PROCESSED":
                return "table-success"
            case "REJECTION":
                return "table-light"
            default:
                return ""
        }
    }
    const statusHum = (status) => {
        switch (status) {
            case "NEW":
                return "Новий"
            case "PROCESSED":
                return "Оброблено"
            case "REJECTION":
                return "Відмова"
            default:
                return ""
        }
    }

    const requestName = (name) => {
        switch (name) {
            case 'pib':
                return 'ПІБ'
            case 'passport':
                return 'Паспорт'
            case 'address':
                return 'Адреса реєстр.'
            case 'realAddress':
                return 'Адреса листування'
            case 'email':
                return 'Email'
            case 'phone':
                return 'Моб. телефон'
            default:
                return name
        }
    }
    const fieldName = fn => {
        switch (fn) {
            case 'country':
                return 'Країна'
            case 'zip':
                return 'Індекс'
            case 'region':
                return 'Область'
            case 'district':
                return 'Район'
            case 'city':
                return 'Місто/селище'
            case 'street':
                return 'Вулиця'
            case 'house':
                return 'Будинок'
            case 'apartment':
                return 'Квартира'
            case 'someAddresses':
                return 'Адр. лист. співпадає'
            case 'sn':
                return 'Серія і номер'
            case 'issuer':
                return 'ким виданий'
            case 'date':
                return 'Коли виданий'
            case 'surname':
                return 'Прізвище'
            case 'firstname':
                return "Ім'я"
            case 'fathername':
                return 'По-батькові'
            default:
                return fn
        }
    }
    const reasonName = (r) => {
        switch (r) {
            case 'marriage':
                return 'Шлюб'
            case 'divorce':
                return 'Розлучення'
            case 'name_change':
                return 'Зміна імені'
            case 'error':
                return 'Помилка в полісі'
            default:
                return r
        }
    }

    const [files, setFiles] = useState({})
    //endregion
    const getListFile = (reqId) => {
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/tools/req4change/${reqId}/files`, {redirect: "error"},
            r => {
                if (r.detail) {
                    setErrorMsg(r.detail)
                    setTimeout(() => setErrorMsg(""), 5000)
                    return
                }
                let d = r
                let fl = d._embedded.attachedFileViews
                setFiles((pf) => {
                    pf[reqId] = fl
                    let fff = {...pf}
                    return fff
                })
            },
            reason => setErrorMsg(reason))
    }

    //region processing
    const processing = (id, status, comment) => {
        setLoading(true)
        const req = {status: status, id: id}
        if (comment) {
            req['comment'] = comment
        }
        fetch(`/admin/api/v1/tools/req4change`, {
            method: "POST",
            redirect: "error",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        }).catch(reason => setErrorMsg(reason))
            .then(() => {
                setLoading(false)
                getData(page.number)
                if (toggleUpdater) {
                    toggleUpdater(prevState => !prevState)
                }
                return
            })
    }

    const [editReq, setEditReq] = useState('')
    const [comment, setComment] = useState('')

    const setReject = (id) => {
        setEditReq(id)
        Modal.getOrCreateInstance(document.getElementById('modal_control_processing')).show()
    }

    //endregion

    //region get person
    let [persons, setPersons] = useState({})
    const getPerson = (id) => {
        if (persons[id]) return persons[id]
        setPersons((p) => {
            p[id] = {}
            let pp = {...p}
            return pp
        })
        fetch(`/admin/api/v1/persons/${id}`, {redirect: "error"})
            .then(r => r.json())
            .then(d => {
                setPersons((p) => {
                    p[id] = d
                    let pp = {...p}
                    return pp
                })
            })
        return {}
    }
    const getPersonName = (id) => {
        let p = getPerson(id)
        if (p) {
            return `${p.surname} ${p.firstname} ${p.fathername}`
        }
        return ''
    }
    //endregion

    return (
        <>
            <div className="row mx-2">
                <div className="col-12 p-2 page-header">
                    <Header showConsultant={false}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-12 p-2 px-5 page-block-min">
                    <div className="my-3">
                        <h4 className={"text-black-50"}>Запити на внесення змін у профіль клієнта</h4>
                        <div className={"border p-3"}>
                            {/*form*/}
                            <form onSubmit={(e) => e.preventDefault()}>
                                <h5>Фільтр</h5>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <label htmlFor="search_by_name"
                                                   className="col-sm-4 col-form-label">Статус</label>
                                            <div className="col-sm-8">
                                                <select id="search_by_name" value={fStatus}
                                                        onChange={event => setFStatus(event.target.value)}
                                                        className="form-control">
                                                    <option value="-">Всі</option>
                                                    <option value="NEW">Нові</option>
                                                    <option value="REJECTION">Відмова</option>
                                                    <option value="PROCESSED">Закриті</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        {/*<button className={"btn btn-success"} onClick={() => getClients(page.number)}>*/}
                                        {/*    <FontAwesomeIcon*/}
                                        {/*        icon={solid("search")}/>*/}
                                        {/*</button>*/}
                                    </div>
                                    <div className="offset-sm-2 col-sm-4">
                                        <div className="row">
                                            <label htmlFor="row_on_page" className={"col-sm-8 col-form-label text-end"}>Зап.
                                                на
                                                ст.</label>
                                            <div className="col-sm-4">
                                                <select id="row_on_page" value={size}
                                                        onChange={(e) => setSize(e.target.value)}
                                                        className="form-control">
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                            {errorMsg}
                        </Alert>}
                        {/*table*/}
                        <div className="table-responsive">
                            {/*{*/}
                            {/*    "created" : "2022-07-15T09:09:34.000+00:00",*/}
                            {/*    "userPhone" : "0631112233",*/}
                            {/*    "personId" : "110008301",*/}
                            {/*    "name" : "address",*/}
                            {/*    "reason" : null,*/}
                            {/*    "data" : "[ {\n  \"country\" : \"Україна\"\n}, {\n  \"zip\" : \"121223\"\n}, {\n  \"region\" : \"\"\n}, {\n  \"district\" : \"\"\n}, {\n  \"city\" : \"dafasdf\"\n},
                             {\n  \"street\" : \"fadsfdaf\"\n}, {\n  \"house\" : \"12\"\n}, {\n  \"apartment\" : \"12\"\n}, {\n  \"someAddresses\" : \"true\"\n} ]",*/}
                            {/*    "status" : "NEW",*/}
                            {/*    "processing" : null,*/}
                            {/*    "adminComment" : null,*/}
                            {/*    "adminUserPhone" : null,*/}
                            {/*    "hasFiles" : true,*/}
                            {/*    "errorFile" : null,*/}
                            {/*    "reqId" : 25*/}
                            {/*}*/}
                            <table className="table mt-4 table-hover">
                                <thead>
                                <tr className="table-dark">
                                    <th>Ід</th>
                                    <th>Створено</th>
                                    <th>Статус</th>
                                    <th>Користувач</th>
                                    <th>Назва запита</th>
                                    <th>Дані</th>
                                    <th>Причина</th>
                                    <th>Файли</th>
                                    <th>Адмін</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((r) => <tr key={r.reqId} className={classTr(r.status)}>
                                    <td>{r.reqId}</td>
                                    <td><span className={"fw-lighter"}>{timeReform(r.created)}</span></td>
                                    <td>{statusHum(r.status)}</td>
                                    <td>Phone: {r.userPhone}<br/>ID: {r.personId} <br/> {getPersonName(r.personId)}</td>
                                    <td>{requestName(r.name)}</td>
                                    <td>{JSON.parse(r.data).map(f => (<p className={"m-0"}
                                                                         key={Object.keys(f)[0]}>{fieldName(Object.keys(f)[0])}: {f[Object.keys(f)[0]] ? f[Object.keys(f)[0]] : '-'}</p>))}</td>
                                    <td>{reasonName(r.reason)}</td>
                                    <td>{!r.hasFiles && <>-</>}
                                        {r.hasFiles &&
                                            <button className="btn btn-light" onClick={() => getListFile(r.reqId)}
                                                    title="Додані файли"><FontAwesomeIcon icon={solid('file-image')}/>
                                            </button>}
                                        <div className={"mt-1 files_box"}>
                                            {files[r.reqId] &&
                                                <>
                                                    {
                                                        files[r.reqId].map((f => (<p className={"m-0"} key={f.id}>
                                                            <a href={`/admin/api/v1/tools/files/${f.id}`}
                                                               target="_blank">{f.name}</a>
                                                        </p>)))
                                                    }
                                                </>
                                            }
                                        </div>
                                    </td>
                                    <td>{r.processing && <>
                                        {timeReform(r.processing)}<br/>
                                        {r.adminUserPhone}<br/>
                                        {r.adminComment}
                                    </>}</td>
                                    <td>
                                        <div className="button_box">
                                            {r.status === 'NEW' && <>
                                                <button onClick={() => {
                                                    processing(r.reqId, "PROCESSED")
                                                }} className="btn btn-success me-2"
                                                        title={"Відмітити як оброблену"}>
                                                    <FontAwesomeIcon
                                                        icon={solid("check")}/>
                                                </button>

                                                <button onClick={() => {
                                                    setReject(r.reqId)
                                                }} className="btn btn-danger"
                                                        title={"Відмовити"}>
                                                    <FontAwesomeIcon
                                                        icon={solid("close")}/>
                                                </button>
                                            </>}
                                        </div>
                                    </td>
                                </tr>)}

                                </tbody>
                            </table>
                        </div>
                        {/*navigation under table*/}
                        <CrudNav page={page} getData={getData} size={size}/>

                        {loading && <div className="d-flex justify-content-center">
                            <div className="spinner-border text-success " role="status">
                                <span className="visually-hidden">Завантаження...</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {/*modal            */}
            <div className="modal fade" id="modal_control_processing" tabIndex="-1"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_control_processingLabel">Коментар до відмови</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label">Коментар</label>
                                <textarea className={"form-control"} value={comment} cols="30" rows="10"
                                          onChange={(event => setComment(event.target.value))}></textarea>
                                <p className={"form-text"}>Цей коментар буде видно тільки адміністрації на цій
                                    сторінці</p>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Відмінити
                            </button>
                            <button type="button" className="btn btn-primary" onClick={() => {
                                processing(editReq, "REJECTION", comment);
                                Modal.getOrCreateInstance(document.getElementById('modal_control_processing')).hide()
                            }}>Зберегти
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}