import React, {useEffect, useState} from "react";
import WrapperFetch from "../../srv_tools/WrapperFetch";

export default function ConsultantList(){
    const [viewConsultant, setViewConsultant]=useState({})
    const [consultants, setConsultants]=useState([])


    useEffect(()=>{
        WrapperFetch.getInstance().enqueue("/api/v1/advisers", {}, data=>{
            setConsultants(data._embedded.consultantClientViews)
            setViewConsultant(data._embedded.consultantClientViews[data._embedded.consultantClientViews.length-1])
        }, e=>{});
    },[])

    const nameView = (c)=>{
        if(!c.clName) return ""
        if(c.clName.toLowerCase() !== "Не Вказано В заяві".toLowerCase()) return c.clName
        return c.agent.name.clName
    }

    return (
        <>
            <h4 className={"text-uppercase text-black-50"}>Мій фінансовий консультант</h4>
            <div className="btn-group">
                <button type="button" className="btn btn-light">
                    {nameView(viewConsultant)} <br/>
                    <a className={"text-black"} href={"tel:" + viewConsultant.phoneMob}>{viewConsultant.phoneMob}</a> <a className={"text-black"} href={"mailto:" + viewConsultant.email}>{viewConsultant.email}</a>
                </button>
                <button type="button" className="btn btn-light dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                    {consultants.map((c)=>(<li key={c.phoneMob+c.clName}> <span className="dropdown-item" onClick={()=>{setViewConsultant(c)}}>{nameView(c)}</span></li>))}
                </ul>
            </div>
        </>
    )
}