export default class WrapperFetch {
    queue = [];

    constructor() {
        this.instance = null;
    }

    static getInstance() {
        if (!this.instance) {
            console.log("new WrapperFetch")
            this.instance = new WrapperFetch();
            this.instance.processQueue();
        }
        return this.instance;
    }

    async processTask() {
        if (this.queue.length > 0) {

            const {url, options, resolve, reject} = this.queue.shift();
            try {
                console.log("time: ", Date.now(), " ", this, " fetching: ", url);
                const response = await fetch(url, options);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                console.log("res1 time: ", Date.now(), " ", this, " fetching: ", url);
                const data = await response.json();
                console.log("res2 time: ", Date.now(), " ", this, " fetching: ", url);
                resolve(data);
            } catch (e) {
                reject(e);
            } finally {

            }
        }
    }

    cntReq = -1;

    processTask2() {
        if (this.queue.length > 0) {
            this.cntReq++;
            const {url, options, resolve, reject} = this.queue.shift();
            setTimeout(() => {
                if (process.env.NODE_ENV === "development") {
                    console.log("time: ", Date.now(), " ", this, " fetching: ", url);
                }
                return fetch(url, options)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        if (process.env.NODE_ENV === "development") {
                            console.log("res1 time: ", Date.now(), " ", this, " fetching: ", url);
                        }
                        this.cntReq--;
                    })
            }, (this.cntReq === 0) ? 1 : 400);
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async processQueue() {
        // cycle
        while (true) {
            this.processTask2();
            if (this.queue.length === 0) {
                await this.sleep(100);
            }
        }
    }

    enqueue(url, options, resolve, reject) {
        this.queue.push({url, options, resolve, reject});
    }
}