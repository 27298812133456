import React, {useEffect, useState} from "react";
import "./SupportControl.scss"
import Header from "../../../header/Header";
import Alert from "../../../common/Alert";
import {solid, thin} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "bootstrap";
import CrudNav from "../common/CrudNav";
import WrapperFetch from "../../../../srv_tools/WrapperFetch";

export default function SupportControl({toggleUpdater}) {
    //region all states
    const [data, setData] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(true)
    const [fStatus, setFStatus] = useState("NEW")

    const [page, setPage] = useState({number: 0})
    const [size, setSize] = useState(20)

    //endregion
    //region load data
    const getMessages = function (p) {
        setLoading(true)
        let st = fStatus === '-' ? '' : fStatus
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/tools/messages?page=${p}&size=${size}&status=${st}`, {redirect: "error"},
            d => {
                setLoading(false)
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setData([])
                    return
                }
                if (d.page)
                    setPage(d.page);
                setData(d._embedded && d._embedded.supportMsgs ? d._embedded.supportMsgs : [])
            },
            e => setErrorMsg(e))
    }

    const timeReform = function (d) {
        //2022-05-21T19:11:07.000+00:00
        if (!d) return;
        return d.split('.')[0].split('T').join(' ')
    }

    useEffect(() => {
        if (!(fStatus)) return;
        getMessages(page.number)
    }, [fStatus])

    //endregion

    //region funcs for buttons
    const setProcessingStatus = (id) => {
        setLoading(true)
        const req = {status: "IN_PROCESSING", id: id}
        fetch(`/admin/api/v1/tools/messages`, {
            method: "PUT",
            redirect: "error",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        })
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then((d) => {
                if (d.error) {
                    setErrorMsg(d.message)
                    return
                }
                getMessages(page.number)
                if (toggleUpdater) {
                    toggleUpdater(prevState => !prevState)
                }
            })
    }

    const setFinished = (id) => {
        if (window.confirm("Після фіналізації повідомлення, ви не можете его змінювати. Продовжити?")) {
            setLoading(true)
            const req = {status: "FINISHED", id: id}
            fetch(`/admin/api/v1/tools/messages`, {
                method: "PUT",
                redirect: "error",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req),
            })
                .catch(reason => setErrorMsg(reason))
                .then(r => r.json())
                .then((d) => {
                    if (d.error) {
                        setErrorMsg(d.message)
                        return
                    }
                    getMessages(page.number)
                    if (toggleUpdater) {
                        toggleUpdater(prevState => !prevState)
                    }
                })
        }
    }
    const [editMsg, setEditMsg] = useState({})
    const [editComment, setEditComment] = useState("")
    const [editStatus, setEditStatus] = useState()
    const addComment = (m) => {
        setEditMsg(m)
        setEditComment(m.adminMessage === null ? "" : m.adminMessage)
        setEditStatus(m.status)
        Modal.getOrCreateInstance(document.getElementById('control_processing')).show()
    }

    const saveComment = () => {
        setLoading(true)
        const req = {status: editStatus, id: editMsg.messageId, message: editComment}
        fetch(`/admin/api/v1/tools/messages`, {
            method: "PUT",
            redirect: "error",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        })
            .catch(reason => setErrorMsg(reason))
            .then(r => r.json())
            .then((d) => {
                if (d.error) {
                    setErrorMsg(d.message)
                    return
                }
                getMessages(page.number)
                if (toggleUpdater) {
                    toggleUpdater(prevState => !prevState)
                }
                Modal.getOrCreateInstance(document.getElementById('control_processing')).hide()
            })
    }

    //endregion

    const classTr = (status) => {
        switch (status) {
            case "NEW":
                return "table-danger"
            case "FINISHED":
                return "table-success"
            case "IN_PROCESSING":
                return "table-active"
            default:
                return ""
        }
    }

    const statusHum = (status) => {
        switch (status) {
            case "NEW":
                return "Новий"
            case "FINISHED":
                return "Закрите"
            case "IN_PROCESSING":
                return "У процессі вирішення"
            default:
                return ""
        }
    }

    return (
        <>
            <div className="row mx-2">
                <div className="col-12 p-2 page-header">
                    <Header showConsultant={false}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-12 p-2 px-5 page-block-min">
                    <div className="my-3">
                        <h4 className={"text-black-50"}>Запити через форму підтримки</h4>
                        <div className={"border p-3"}>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <h5>Фільтр</h5>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <label htmlFor="search_by_name"
                                                   className="col-sm-4 col-form-label">Статус</label>
                                            <div className="col-sm-8">
                                                <select id="search_by_name" value={fStatus}
                                                        onChange={event => setFStatus(event.target.value)}
                                                        className="form-control">
                                                    <option value="-">Всі</option>
                                                    <option value="NEW">Нові</option>
                                                    <option value="IN_PROCESSING">В обробці</option>
                                                    <option value="FINISHED">Закриті</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        {/*<button className={"btn btn-success"} onClick={() => getClients(page.number)}>*/}
                                        {/*    <FontAwesomeIcon*/}
                                        {/*        icon={solid("search")}/>*/}
                                        {/*</button>*/}
                                    </div>
                                    <div className="offset-sm-2 col-sm-4">
                                        <div className="row">
                                            <label htmlFor="row_on_page" className={"col-sm-8 col-form-label text-end"}>Зап.
                                                на
                                                ст.</label>
                                            <div className="col-sm-4">
                                                <select id="row_on_page" value={size}
                                                        onChange={(e) => setSize(e.target.value)}
                                                        className="form-control">
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                            {errorMsg}
                        </Alert>}
                        <div className="table-responsive">
                            <table className="table mt-4 table-hover">
                                <thead>
                                <tr className={"table-dark"}>
                                    <th>Ід</th>
                                    <th>Створено</th>
                                    <th>Статус</th>
                                    <th>Користувач</th>
                                    <th>Контакти</th>
                                    <th>Повідомлення</th>
                                    <th>Адмін</th>
                                    <th>Данні</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((m) => (<tr key={m.messageId} className={classTr(m.status)}>
                                    <td>{m.messageId}</td>
                                    <td><span className={"fw-lighter"}>{timeReform(m.created)}</span></td>
                                    <td>{statusHum(m.status)}</td>
                                    <td>{m.userPhone}</td>
                                    <td>{m.phone}<br/> {m.email}<br/> {m.name}</td>
                                    <td>{m.message}</td>
                                    <td>{m.updated && <span
                                        className={"fw-lighter"}>{timeReform(m.updated)}: <br/></span>}{m.adminUserPhone && <>{m.adminUserPhone}
                                        <br/>{m.adminMessage}</>}</td>
                                    <td>Відправлено адміну: <br/> {timeReform(m.timeAdminAlert)}
                                        <br/>Результат: <span className={"fw-lighter"}>{m.resultAdminAlert}</span></td>
                                    <td className={"width-btns-3"}>
                                        {m.status === "NEW" &&
                                            <button onClick={() => setProcessingStatus(m.messageId)}
                                                    className="btn btn-primary me-2" title={"Взяти до роботи"}>
                                                <FontAwesomeIcon
                                                    icon={solid("toggle-on")}/>
                                            </button>
                                        }
                                        {m.status !== "FINISHED" &&
                                            <button onClick={() => addComment(m)} className="btn btn-success me-2"
                                                    title={"Додати коментар і встановити статус"}>
                                                <FontAwesomeIcon
                                                    icon={solid("tools")}/>
                                            </button>
                                        }
                                        {m.status !== "FINISHED" &&
                                            <button onClick={() => setFinished(m.messageId)} className="btn btn-danger"
                                                    title={"Закрити"}>
                                                <FontAwesomeIcon
                                                    icon={solid("close")}/>
                                            </button>
                                        }
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                        <CrudNav size={size} page={page} getData={getMessages}/>
                        {loading && <div className="d-flex justify-content-center">
                            <div className="spinner-border text-success " role="status">
                                <span className="visually-hidden">Завантаження...</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            <div className="modal fade" id="control_processing" tabIndex="-1"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="control_processingModalLabel">Коментар к зверненню</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label className="form-label">Коментар</label>
                                <textarea className={"form-control"} value={editComment} cols="30" rows="10"
                                          onChange={(event => setEditComment(event.target.value))}></textarea>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Статус</label>
                                <select className="form-select" name="" id="cu_status"
                                        value={editStatus} onChange={event => setEditStatus(event.target.value)}>
                                    <option value="IN_PROCESSING">У процесі вирішення</option>
                                    <option value="FINISHED">Закрите</option>
                                </select>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Відмінити
                            </button>
                            <button type="button" className="btn btn-primary" onClick={() => saveComment()}>Зберегти
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}