import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Tooltip} from 'bootstrap'
import InsAmounts from "./InsAmounts";
import IndexingRequest from "./IndexingRequest";
import Alert from "../../common/Alert";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function CommonInfo({contract, user}) {
    const [debt, setDebt] = useState("Завантаження...")
    const [debtAmount, setDebtAmount] = useState(0)
    const [modeIR, setModeIR] = useState(false)
    const [msg, setMsg] = useState("")
    useEffect(() => {
        if (msg) {
            setTimeout(() => {
                setMsg("")
            }, 8000)
        }
    }, [msg])
    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }, [])
    const contractId = contract.id
    useEffect(() => {
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contractId}/debt`, {redirect: "error"}, d => {
            setDebt(Math.abs(d.debt) + ' ' + contract.currency)
            setDebtAmount(d.debt)
        }, e => {})
    }, [contractId, contract.currency])
    const c = contract
    if (contract.error) return (<></>);
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-8">
                    <h4>{contract.printId} <span className={"text-black-50"}>{contract.prgIns.name}</span></h4>
                    <span
                        className={"badge rounded-pill " + (contract.status.isClose ? "bg-secondary" : "bg-success")}>{contract.status.statusName}</span>
                </div>
                <div className="col-12 col-md-4 text-end">
                    {/*https://gli.com.ua/pay/?amount=$amount&paymentId=$paymentId&email=$email&userId=$userId&numContract=$numContract&fio=$vu.urlEncode($fio)&merchantId=$merchantId&crc=$crc*/}
                    <a href={`https://gli.com.ua/pay/?amount=${c.inpayContr * 100}&email=${user.person.email}&numContract=${c.id}&fio=${user.clName}&crc=${c.currency}&contract_date=${c.begDate}&inn=${user.person.taxID}`}
                       className={"btn btn-danger w-75 text-uppercase rounded-pill"} target="_blank"
                       rel="noreferrer">Сплатити</a>
                    <button className={"btn btn-success w-75 text-uppercase my-2 rounded-pill"} data-bs-toggle="modal"
                            data-bs-target="#change_contract">Внести зміни
                    </button>
                </div>
            </div>

            <hr/>
            {msg &&
                <Alert header={"Результат обробки запиту"} type={"success"} addClass={"my-4"}>
                    {msg}
                </Alert>
            }
            <div className="row">
                <div className="col-md-5">
                    <div className="row my-3">
                        <div className="col-6 fw-bold">Початок дії:</div>
                        <div className="col-6">{c.begDate}</div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 fw-bold">Закінчення дії:</div>
                        <div className="col-6">{c.endDateContr}</div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 fw-bold">Строк дії, років:</div>
                        <div className="col-6">{c.termContr}</div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="row my-3 ">
                        <div className="col-6 fw-bold">Страховий внесок:</div>
                        <div className="col-6">{c.inpayContr} {c.currency}
                            {c.isCpi &&
                                <span data-bs-toggle="tooltip" data-bs-placement="right"
                                      title="Індексація внеску активна" onClick={() => setModeIR(true)}
                                      style={{cursor: "pointer"}}>
                                    <FontAwesomeIcon icon={regular("check-square")} className={"ms-2 text-success"}
                                                     size={"1x"}/>
                                </span>
                            }
                            {!c.isCpi &&
                                <span data-bs-toggle="tooltip" data-bs-placement="right"
                                      title="Індексація внеску неактивна" onClick={() => setModeIR(true)}
                                      style={{cursor: "pointer"}}>
                                    <FontAwesomeIcon icon={regular("window-close")} className={"ms-2 text-danger"}
                                                     size={"1x"}/>
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 fw-bold">Періодичність сплати:</div>
                        <div className="col-6">{c.freqPay.freqName}</div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6 fw-bold">{debtAmount >= 0 && <>Заборгованість</>}{debtAmount < 0 && <>Переплата</>}:</div>
                        <div className="col-6">
                            {debtAmount > 0 && <span className={"text-danger"}>{debt}</span>}{debtAmount <= 0 && <span className={"text-success"}>{debt}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <InsAmounts contractId={contractId}/>

            <div className="modal fade" id="change_contract" tabIndex="-1"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="change_contractLabel">Внести зміни</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <p>Для внесення змін до Договору, будь ласка, заповніть заяву, що додається, та надішліть:</p>

                            <p className={"text-black"}><strong>Укрпоштою:</strong> ТДВ «СК «ГЛІ»,<br/>вул. Кирилівська, 160-Б, оф. 40, 04073, м. Київ.</p>

                            <p className={"text-black"}><strong>Новою поштою:</strong> м. Київ Поштомат № 35065<br/> (вул. Кирилівська, 160-Б (хлібна кава)), <br/>(Дмитрик Ірина, +38 050 222 32 81).</p>
                            <hr/>
                            <p><a className={"text-danger text-decoration-none"}
                                  href="https://gli.com.ua/wp-content/uploads/2022/07/Zmina-umov-strahuvannya.pdf"
                                  target={"_blank"} rel="noreferrer"><FontAwesomeIcon icon={solid("file-pdf")}
                                                                                      size={"3x"}/> Заява на внесення
                                змін до Договору</a></p>
                        </div>

                    </div>
                </div>
            </div>
            {modeIR && <IndexingRequest contractId={contractId} cpiNow={c.isCpi} cb={(m) => {
                setMsg(m);
                setModeIR(false)
            }}/>}
        </>
    )
}