import React from "react";
import Header from "../../header/Header";
import ShortContracts from "../contracts/ShortContracts";
import {Link} from "react-router-dom";
import InsuredEvent from "../payments/InsuredEvent";

export default function Main(){
    const isBeta = process.env.REACT_APP_IS_BETA || process.env.NODE_ENV === "development"
    if(window.localStorage['user'] && window.localStorage['user'].length >= 2){
        setTimeout(()=>{
            if(!JSON.parse(window.localStorage['user']).clName) {
                window.location.replace("/users");
            }
        }, 1000)

    }
    return (
        <>
            <div className="row mx-2">
                <div className="col-md-12 p-2 page-header" >
                    <Header />
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-md-8 p-3 px-5 page-block-min">
                    <ShortContracts/>
                    <div className={"text-center my-4"}>
                        <Link className={"text-black text-decoration-none"} to={"/contracts"}>Дивитись всі >>></Link>
                    </div>
                </div>
                <div className="col-md-4 ps-md-2 pt-2 pt-sm-0 " >
                    <div className="col-md-12 page-block-min p-5 text-center" >
                        <InsuredEvent /><br/>
                        <Link to={"/schedule"} className={"btn btn-success text-uppercase my-2 rounded-pill"} >Графік платежів</Link>
                    </div>
                    {isBeta &&
                        <div className="col-md-12 my-2 page-block-min p-5 text-center">
                            Функціонал в розробці
                        </div>
                    }
                </div>
            </div>
        </>
    )
}