import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function InsAmounts({contractId}) {
    const [amounts, setAmounts] = useState([])
    const [errorMsg, setErrorMsg] = useState("")

    useEffect(() => {
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contractId}/summa`, {redirect: "error"},
            o => setAmounts(o._embedded.sumaContracts),
            reason => setErrorMsg(reason)
        )
    }, [contractId])
    return (
        <div className={"my-5"}>
            <h4 className={"text-uppercase"}>Страхове покриття</h4>
            {errorMsg &&
                <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                    {errorMsg}
                </Alert>
            }
            {amounts.map((a => (
                <div className="row my-4" key={a.id}>
                    <div className="col-9">
                        {a.event.eventDescAcc}
                    </div>
                    <div className="col-2">
                        {a.eventId === 10 && <>XXX</>}
                        {a.typeSum.typeSumName === "зростаюча" && <>повернення внесків</>}
                        {a.eventId !== 10 && a.typeSum.typeSumName !== "зростаюча" && <>{a.amount} {a.currency}</>}

                    </div>
                </div>
            )))}

        </div>
    )
}