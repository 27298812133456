import React, {useEffect, useState} from "react";
import Header from "../../../header/Header";
import Alert from "../../../common/Alert";
import CrudNav from "../common/CrudNav";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "bootstrap";
import WrapperFetch from "../../../../srv_tools/WrapperFetch";

export default function IndexingControl({toggleUpdater}) {
    const [data, setData] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(true)

    const [fStatus, setFStatus] = useState("NEW")
    const [page, setPage] = useState({number: 0})
    const [size, setSize] = useState(20)

    //region load data
    const getData = function (p) {
        setLoading(true)
        let st = fStatus === '-' ? '' : fStatus
        WrapperFetch.getInstance().enqueue(`/admin/api/v1/tools/indexing_requests?page=${p}&size=${size}&status=${st}`, {redirect: "error"},
            d => {
                setLoading(false)
                if (d.error) {
                    setErrorMsg(d.error + (d.status ? '; Status: ' + d.status : ""))
                    setData([])
                    return
                }
                if (d.page)
                    setPage(d.page);
                setData(d._embedded && d._embedded.indexingRequests ? d._embedded.indexingRequests : [])
            },
            reason => setErrorMsg(reason));
    }

    const timeReform = function (d) {
        //2022-05-21T19:11:07.000+00:00
        if (!d) return;
        return d.split('.')[0].split('T').join(' ')
    }
    useEffect(() => {
        if (!(fStatus)) return;
        getData(page.number)
    }, [fStatus])
    //endregion

    //region view functions
    const classTr = (status) => {
        switch (status) {
            case "NEW":
                return "table-danger"
            case "PROCESSED":
                return "table-success"
            case "REJECTION":
                return "table-light"
            default:
                return ""
        }
    }
    const statusHum = (status) => {
        switch (status) {
            case "NEW":
                return "Новий"
            case "PROCESSED":
                return "Оброблено"
            case "REJECTION":
                return "Відмова"
            default:
                return ""
        }
    }

    //endregion

    //region processing
    const processing = (id, status, comment) => {
        setLoading(true)
        const req = {status: status, id: id}
        if (comment) {
            req['comment'] = comment
        }
        fetch(`/admin/api/v1/tools/indexing_requests`, {
            method: "POST",
            redirect: "error",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        }).catch(reason => setErrorMsg(reason))
            .then(() => {
                setLoading(false)
                getData(page.number)
                if (toggleUpdater) {
                    toggleUpdater(prevState => !prevState)
                }
                return
            })
    }

    const [editReq, setEditReq] = useState('')
    const [comment, setComment] = useState('')

    const setReject = (id) => {
        setEditReq(id)
        Modal.getOrCreateInstance(document.getElementById('modal_control_processing')).show()
    }

    //endregion

    //region get person
    let [persons, setPersons] = useState({})
    const getPerson = (id) => {
        if (persons[id]) return persons[id]
        setPersons((p) => {
            p[id] = {}
            let pp = {...p}
            return pp
        })
        fetch(`/admin/api/v1/persons/${id}`, {redirect: "error"})
            .then(r => r.json())
            .then(d => {
                setPersons((p) => {
                    p[id] = d
                    let pp = {...p}
                    return pp
                })
            })
        return {}
    }
    const getPersonName = (id) => {
        let p = getPerson(id)
        if (p) {
            return `${p.surname} ${p.firstname} ${p.fathername}`
        }
        return ''
    }
    //endregion

    return (<>
        <div className="row mx-2">
            <div className="col-12 p-2 page-header">
                <Header showConsultant={false}/>
            </div>
        </div>
        <div className="row g-0 mx-2 my-2">
            <div className="col-12 p-2 px-5 page-block-min">
                <div className="my-3">
                    <h4 className={"text-black-50"}>Запити на зміну індексації в договорі</h4>
                    <div className={"border p-3"}>
                        {/*form*/}
                        <form onSubmit={(e) => e.preventDefault()}>
                            <h5>Фільтр</h5>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <label htmlFor="search_by_name"
                                               className="col-sm-4 col-form-label">Статус</label>
                                        <div className="col-sm-8">
                                            <select id="search_by_name" value={fStatus}
                                                    onChange={event => setFStatus(event.target.value)}
                                                    className="form-control">
                                                <option value="-">Всі</option>
                                                <option value="NEW">Нові</option>
                                                <option value="REJECTION">Відмова</option>
                                                <option value="PROCESSED">Закриті</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    {/*<button className={"btn btn-success"} onClick={() => getClients(page.number)}>*/}
                                    {/*    <FontAwesomeIcon*/}
                                    {/*        icon={solid("search")}/>*/}
                                    {/*</button>*/}
                                </div>
                                <div className="offset-sm-2 col-sm-4">
                                    <div className="row">
                                        <label htmlFor="row_on_page" className={"col-sm-8 col-form-label text-end"}>Зап.
                                            на
                                            ст.</label>
                                        <div className="col-sm-4">
                                            <select id="row_on_page" value={size}
                                                    onChange={(e) => setSize(e.target.value)}
                                                    className="form-control">
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {errorMsg && <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                        {errorMsg}
                    </Alert>}
                    {/*table*/}
                    <div className="table-responsive">
                        <table className="table mt-4 table-hover">
                            <thead>
                            <tr className="table-dark">
                                <th>Ід</th>
                                <th>Створено</th>
                                <th>Статус</th>
                                <th>Користувач</th>
                                <th>Договір</th>
                                <th><span className="ms-3">Дія</span></th>
                                <th>Адмін</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((r) => <tr key={r.reqId} className={classTr(r.status)}>

                                <td>{r.reqId}</td>
                                <td><span className={"fw-lighter"}>{timeReform(r.created)}</span></td>
                                <td>{statusHum(r.status)}</td>
                                <td>Phone: {r.userPhone}<br/>ID: {r.personId} <br/>{getPersonName(r.personId)}</td>
                                <td>{r.contractId}</td>
                                <td>{r.isIndexed && <>
                                    <FontAwesomeIcon icon={regular("window-close")} className={"text-danger"}
                                                     size={"1x"}/>
                                    <FontAwesomeIcon icon={solid("arrow-right")} className={"ms-2 text-success"}
                                                     size={"1x"}/>
                                    <FontAwesomeIcon icon={regular("check-square")} className={"ms-2 text-success"}
                                                     size={"1x"}/>
                                </>}
                                    {!r.isIndexed && <>
                                        <FontAwesomeIcon icon={regular("check-square")} className={"text-success"}
                                                         size={"1x"}/>
                                        <FontAwesomeIcon icon={solid("arrow-right")} className={"ms-2 text-danger"}
                                                         size={"1x"}/>
                                        <FontAwesomeIcon icon={regular("window-close")} className={"ms-2 text-danger"}
                                                         size={"1x"}/>
                                    </>}
                                </td>
                                <td>
                                    {r.processing && <>
                                        {timeReform(r.processing)}<br/>
                                        {r.adminUserPhone}<br/>
                                        {r.adminComment}
                                    </>}
                                </td>
                                <td>
                                    <div className="button_box">
                                        {r.status === 'NEW' && <>
                                            <button onClick={() => {
                                                processing(r.reqId, "PROCESSED")
                                            }} className="btn btn-success me-2"
                                                    title={"Відмітити як оброблену"}>
                                                <FontAwesomeIcon
                                                    icon={solid("check")}/>
                                            </button>

                                            <button onClick={() => {
                                                setReject(r.reqId)
                                            }} className="btn btn-danger"
                                                    title={"Відмовити"}>
                                                <FontAwesomeIcon
                                                    icon={solid("close")}/>
                                            </button>
                                        </>}
                                    </div>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                    {/*navigation under table*/}
                    <CrudNav page={page} getData={getData} size={size}/>

                    {loading && <div className="d-flex justify-content-center">
                        <div className="spinner-border text-success " role="status">
                            <span className="visually-hidden">Завантаження...</span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        {/*modal            */}
        <div className="modal fade" id="modal_control_processing" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal_control_processingLabel">Коментар до відмови</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Коментар</label>
                            <textarea className={"form-control"} value={comment} cols="30" rows="10"
                                      onChange={(event => setComment(event.target.value))}></textarea>
                            <p className={"form-text"}>Цей коментар буде видно тільки адміністрації на цій
                                сторінці</p>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Відмінити
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => {
                            processing(editReq, "REJECTION", comment);
                            Modal.getOrCreateInstance(document.getElementById('modal_control_processing')).hide()
                        }}>Зберегти
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}