import React from "react";

export default function Cell({year, contract, inpays}){
    if(year === undefined || inpays === undefined){
        return (<td></td>)
    }
    const [text, cls, rowspan] = genTextCell(year, contract, inpays);
    // console.log(cls)
    if (rowspan){
        return (
            <td className={cls} rowSpan={rowspan}>
                {text}
            </td>
        )
    }
    if (contract.isOneTime && getYear(contract.finish) >= year && getYear(contract.start) < year){
        return (<></>)
    }
    return (
        <td className={cls}>
            {text}
        </td>
    )
}

function getYear(date){
    return parseInt(date.split("-")[0]);
}

function genTextCell(year, contract, inpays){
    const statusToClass = (status)=>{
        switch (status) {
            case "PAYED":
                return "table-success";
            case "DEBT":
                return "table-secondary";
            case "FUTURE":
                return "p-future";
            default:
                return "";
        }
    }

    if (contract.isOneTime){
        if (inpays.length > 0 && year === getYear(inpays[0].oneTimePaymentDate)){
            let rowspan = getYear(contract.finish) - getYear(contract.start) + 1
            return [(inpays[0].amount + " " + contract.currency), statusToClass(inpays[0].status), rowspan]
        }
        return  ["", ""]
    }

    if (year < getYear(inpays[0].inpayDate)){
        return  ["", ""]
    }
    if (year > getYear(inpays[inpays.length-1].inpayDate)) {
        return ["", ""]
    }


    let vals = inpays
        .filter((inp)=> year === getYear(inp.inpayDate))
        .map(inp=>{
            return [(inp.amount + " " + contract.currency), statusToClass(inp.status)];
        })

    try {
        return [vals[0][0], vals[0][1]];
    }catch (err){
        // console.log("error:", err)
        return ["", ""]
    }finally {
        // console.log(year, contract, inpays)
        // console.log(vals)
    }


}