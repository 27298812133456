import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import "./Payments.scss"
import {Tooltip} from "bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import printJS from "print-js";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function Payments({contract, user}) {
    const isBeta = process.env.REACT_APP_IS_BETA || process.env.NODE_ENV === "development"
    const [total, setTotal] = useState(0)
    const [wasPayed, setWasPayed] = useState(0)
    const [debt, setDebt] = useState(0)
    const [errorMsg, setErrorMsg] = useState("")
    const [calendar, setCalendar] = useState([])
    const [isOneTime] = useState(contract.freqPay.freqEqu === "1" && !contract.freqPay.freqYear)
    const [indexYears, setIndexYears] = useState([])

    useEffect(() => {
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contract.id}/amounts`, {redirect: "error"},
            d => {
                setTotal(d.total)
                setWasPayed(d.wasPayed)
                setDebt(d.debt)
            },
            reason => setErrorMsg(reason))
    }, [contract])

    useEffect(() => {
        WrapperFetch.getInstance().enqueue(`/api/v1/contracts/${contract.id}/calendar`, {redirect: "error"},
            d => {
                if (d._embedded && d._embedded.calendarPaymentViews)
                    setCalendar(d._embedded.calendarPaymentViews);
                var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
                tooltipTriggerList.map(function (tooltipTriggerEl) {
                    return new Tooltip(tooltipTriggerEl)
                })
            },
            reason => setErrorMsg(reason))
    }, [contract])

    useEffect(() => {
        if (!isOneTime) return
        let cntYears = getYear(contract.endDateContr) - getYear(contract.begDate) + 1
        let startYear = getYear(contract.begDate)
        let index = []
        let amountPayment = (y) => {
            for (let i in calendar) {
                if (getYear(calendar[i].oneTimePaymentDate) === y) {
                    return calendar[i].amount
                }
            }
            return 0
        }
        for (let i = 0; i < cntYears; i++) {
            index.push({y: startYear + i, a: amountPayment(startYear + i)})
        }
        setIndexYears(index)
    }, [calendar])
    // useEffect(() => {
    //     console.log("payments user", user)
    // }, [user])

    const statusToClass = (status) => {
        switch (status) {
            case "PAYED":
                return "p-payed";
            case "DEBT":
                return "p-debt";
            case "FUTURE":
                return "p-future";
            default:
                return "";
        }
    }

    let amount = function (cal) {
        if (cal.amountContract !== cal.amount) {
            let a = cal.amountContract - cal.amount
            // round to 2 digits
            return Math.round(a * 100) / 100
        }
        return cal.amount
    }

    const [errorMsg2, setErrorMsg2] = useState("")
    const [loading, setLoading] = useState(false)
    const [receipt, setReceipt] = useState({})
    const [receiptMsg, setReceiptMsg] = useState("")
    let onclickReceipt = function () {
        setLoading(true)
        fetch(`/api/v1/contracts/${contract.id}/receipt`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: "error"
        }).catch(reason => {
            setErrorMsg2(reason)
            setLoading(false)
        }).then(r => r.json())
            .then(r => {
                setErrorMsg2("")
                setLoading(false)
                console.log(r)
                if (r.error) {
                    setErrorMsg2(r.error)
                    return
                }
                setReceipt(r)
                setReceiptMsg("Квитанцію отримано. Виберіть дію")
            })


    }

    let onclickPrintReceipt = () => {
        printJS("/pdf/" + receipt.receipt)
    }

    let onclickSendReceipt = () => {
        let data = new FormData()
        data.append('fileName', receipt.receipt)
        setLoading(true)
        fetch(`/api/v1/contracts/${contract.id}/receipt/send`, {
            method: 'POST',
            body: data,
            redirect: "error"
        }).catch(reason => setErrorMsg(reason))
            .then(r => {
                setLoading(false)
                if (r.status > 201) {
                    setErrorMsg2(r.statusText)
                    return
                }
                setReceiptMsg("Квитанцію відправлено!")
            })
    }

    const c = contract
    if (contract.error) return (<></>);
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <h4>{contract.printId} <span className={"text-black-50"}>{contract.prgIns.name}</span></h4>
                    <span
                        className={"badge rounded-pill " + (contract.status.isClose ? "bg-secondary" : "bg-success")}>{contract.status.statusName}</span>
                    {errorMsg &&
                        <Alert header={"Помилка отримання даних!"} type={"danger"} addClass={"my-4"}>
                            {errorMsg}
                        </Alert>
                    }
                    <div className="row my-2">
                        <div className="col-md-6">
                            {isOneTime &&
                                <>
                                    {indexYears.map((y) => (
                                        <div className="row lh-lg" key={y.y}>
                                            <div
                                                className="col-6 text-center border border-light">{y.y}</div>
                                            <div className={"col-6 text-center p-payed"}
                                                 data-bs-toggle="tooltip" data-bs-placement="right"
                                            >{y.a} {c.currency}</div>
                                        </div>
                                    ))}
                                </>
                            }
                            {!isOneTime &&
                                <> {calendar.map((cal) => (
                                    <div className="row lh-lg" key={cal.id}>
                                        <div
                                            className="col-6 text-center border border-light">{cal.inpayDate.split("-")[0]}</div>
                                        <div className={"col-6 text-center " + statusToClass(cal.status)}
                                             data-bs-toggle="tooltip" data-bs-placement="right"
                                             title={"Очікується внесок до " + cal.inpayDate}>{amount(cal)} {c.currency}</div>
                                    </div>
                                ))
                                }</>
                            }
                        </div>
                        {!isOneTime &&
                            <div className="col-md-2 d-flex align-items-end">
                                <div>
                                    <div className="col-12 p-payed text-center py-2">сплачено</div>

                                    <div className="col-12 p-debt text-center p-2">заборгованість</div>

                                    <div className="col-12 p-future text-center py-2">очікується</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-6 ">
                    <p><span className={"fw-bold"}>Всього сплачено:</span> {wasPayed} {contract.currency}</p>
                    <p><span className={"fw-bold"}>Всього має бути сплачено:</span> {total} {contract.currency}</p>
                    <p><span
                        className={"fw-bold"}>{debt >= 0 && <>Заборгованість</>}{debt < 0 && <>Переплата</>}:</span> {debt > 0 &&
                        <span className={"text-danger"}>{debt}</span>}{debt <= 0 &&
                        <span className={"text-success"}>{-1 * debt}</span>} {contract.currency}</p>

                    {/*https://gli.com.ua/pay/?amount=$amount&paymentId=$paymentId&email=$email&userId=$userId&numContract=$numContract&fio=$vu.urlEncode($fio)&merchantId=$merchantId&crc=$crc*/}
                    <a href={`https://gli.com.ua/pay/?amount=${c.inpayContr * 100}&email=${user.person.email}&numContract=${c.id}&fio=${user.clName}&crc=${c.currency}&contract_date=${c.begDate}&inn=${user.person.taxID}`}
                       className={"btn btn-danger w-50 text-uppercase rounded-pill "} target="_blank"
                       rel="noreferrer">Сплатити</a>

                    <br/>
                    <button className={"btn btn-secondary text-center text-uppercase rounded-pill w-50 my-2"}
                            data-bs-toggle="modal" data-bs-target="#receipt_resp" onClick={onclickReceipt}>Друк платіжки
                    </button>


                </div>
            </div>

            <div className="modal fade" id="receipt_resp" tabIndex="-1"
                 aria-hidden="true">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="receipt_respLabel">Квітанція</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">

                            {loading && <div className="d-flex justify-content-center">
                                <div className="spinner-border text-success " role="status">
                                    <span className="visually-hidden">Завантаження...</span>
                                </div>
                            </div>}

                            {errorMsg2 && <Alert header={"Помилка обробки даних!"} type={"danger"} addClass={"my-4"}>
                                {errorMsg2}
                            </Alert>}
                            {receiptMsg && <p>{receiptMsg}</p>}

                        </div>
                        {receiptMsg &&
                            <div className={"text-center ps-0 border-top py-2"}>
                                <a href={"/pdf/" + receipt.receipt} className={"btn btn-info rounded-pill"}
                                   target="_blank" rel={"noreferrer"}><FontAwesomeIcon icon={solid("file-download")}
                                                                                       size={"1x"}/> Завантажити</a>
                                <button className={"btn btn-primary mx-2 rounded-pill"} onClick={onclickPrintReceipt}>
                                    <FontAwesomeIcon icon={solid("print")} size={"1x"}/> Роздрукувати
                                </button>
                                <button className={"btn btn-success rounded-pill"} onClick={onclickSendReceipt}>
                                    <FontAwesomeIcon icon={solid("mail-forward")} size={"1x"}/> На email
                                </button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

function getYear(date) {
    return parseInt(date.split("-")[0]);
}