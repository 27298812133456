import React, {useEffect, useRef, useState} from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function SelectRole({currentRoles, resultHandle}) {
    const [selectState, setSelectState] = useState(false)
    const [cuRole, setCuRole] = useState(["ROLE_USER"])

    useEffect(() => {
        setCuRole(currentRoles.split(','))
    }, [])

    useEffect(() => {
        if (selectState) {
            selHtml.current.focus();
        }
    }, [selectState])

    const selHtml = useRef(null)

    const finish =function (){
        if(resultHandle)resultHandle(cuRole)
        setSelectState(false)
    }

    if (!selectState) {
        return (
            <button className={"btn btn-secondary ms-1"} title="Змінити роль користувача"
                    onClick={() => setSelectState(true)}>
                <FontAwesomeIcon icon={solid("users-gear")}/>
            </button>
        )
    }

    return (
        <div className={"row mt-2"}>
            <div className="col-8">
                <select ref={selHtml} className="form-select" multiple
                        value={cuRole} onChange={event => {
                    setCuRole([...event.target.options].filter(({selected}) => selected).map(({value}) => value))
                }}
                        onKeyDown={event => setSelectState(event.code !== "Escape")}
                >
                    <option value="ROLE_USER">Користувач</option>
                    <option value="ROLE_ADMIN">Адмін</option>
                </select>
                <div className="form-text">
                    Для множинного вибору використовуйте <mark>ctrl+click</mark>, або <mark>shift+click</mark>
                </div>
            </div>
            <div className="col-4">
                <button  className={"btn btn-success"} onClick={()=>{finish()}} >
                    <FontAwesomeIcon icon={solid("check")}/>
                </button>
                <button className={"btn btn-light ms-1"} onClick={()=>{setSelectState(false)}}  title="Відміна">
                    <FontAwesomeIcon icon={solid("close")}/>
                </button>
            </div>
        </div>
    )
}