import React, {useEffect, useState} from "react";
import Alert from "../../common/Alert";
import {Link} from "react-router-dom";
import moment from "moment";
import "moment/locale/uk"
import CellYear from "./CellYear";
import {Tooltip} from "bootstrap";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function Year() {
    const [arrContracts, setArrContracts] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [data, setData] = useState({})
    moment.locale("uk")
    const cy = moment().year();
    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/current_year_calendar", {redirect: "error"},
            d => {
                setArrContracts(d.contracts)
                setData(d.data)
            },
            reason => setErrorMsg(reason))
    }, [])
    useEffect(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }, [data])
    if (!arrContracts || arrContracts.length === 0) {
        return (<></>)
    }
    return (
        <div className={"my-4"}>
            {errorMsg &&
                <Alert header={"Помилка отримання даних!"} type={"danger"}>
                    {errorMsg}
                </Alert>
            }
            <div className="table-responsive fix-header-table">
                <table className="table table-bordered">
                    <thead className={"table-secondary"}>
                    <tr className={"text-center"}>
                        <th></th>
                        {arrContracts.map((c) => (<th key={c.id}>
                            <Link to={`/contracts/${c.id}`}
                                  className={"text-black text-decoration-none"}>{c.id}</Link>

                        </th>))}
                    </tr>
                    </thead>
                    <tbody className="text-center">
                    {moment.months().map((r, nm) => (<tr key={r}>
                        <th className={"table-secondary"}>{r}<br/>{cy}</th>
                        {arrContracts.map((c) => (<CellYear key={c.id} month={nm+1} contract={c} inpays={data[c.id]}/>))}
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}