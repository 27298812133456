import React, {useEffect, useState} from "react";
import Header from "../../header/Header";
import InsuredEvent from "./InsuredEvent";
import Alert from "../../common/Alert";
import Files from "../../common/Files";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function Payments() {
    const [events, setEvents] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")
    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/insuredEvents", {redirect: "error"},
                d => setEvents(d._embedded ? d._embedded.insuredEventUserViews : []),
                e => setErrorMsg(e)
        )
    }, [])
    return (
        <>
            <div className="row mx-2">
                <div className="col-md-12 p-2 page-header">
                    <Header showConsultant={false}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-md-12 p-3 px-5 page-block-min">
                    <div className="my-3">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <InsuredEvent btnText="Повідомити про страховий випадок" btnAddClass=""/>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-12">
                                <p>
                                    Зі стандартним переліком документів, необхідних для проведення страхової виплати,
                                    можно ознайомитися <a
                                    href="https://gli.com.ua/diyi-pry-strahovomu-vypadku/#finances" target="_blank"
                                    rel="noreferrer">на сайті</a>.
                                </p>
                            </div>
                        </div>
                        <h4 className={"text-black-50 text-uppercase"}>Мої страхові виплати</h4>
                        {!events &&
                            <p>У вас немає страхових виплат</p>
                        }
                        {errorMsg &&
                            <Alert header={"Помилка отримання даних!"} type={"danger"}>
                                {errorMsg}
                            </Alert>
                        }
                        {events &&
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Дата повідомлення</th>
                                        <th>Номер договору</th>
                                        <th>Ризик</th>
                                        <th>Статус</th>
                                        <th>Документи</th>
                                        <th>Сума виплати</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/*amount: null*/}
                                    {/*contract: "1111111111"*/}
                                    {/*created: "2022-04-29"*/}
                                    {/*currency: null*/}
                                    {/*id: 1*/}
                                    {/*pathToDocs: null*/}
                                    {/*status: "WAITING"*/}

                                    {events.map((e) => (<tr key={e.id}>
                                        <td>{e.created}</td>
                                        <td>{e.contract}</td>
                                        <td></td>
                                        <td><strong>{convertStatus(e.status)} </strong> {e.status === "PAYED" && e.payed && <>
                                            <br/>{e.payed}</>}
                                            {e.status === "REFUSAL" && e.refusal && <>
                                                <br/>{e.refusal}</>}
                                            {e.status === "REFUSAL" && e.message && <>
                                                <br/><i>{e.message}</i></>}
                                        </td>
                                        <td>
                                            {e.files && <Files files={e.files} url="/api/v1/insuredEvents/files" />}
                                        </td>
                                        <td>{e.amount && e.currency && <>{e.amount} {e.currency}</>}</td>
                                    </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </div>

            </div>
        </>
    )
}

function convertStatus(status) {
    switch (status) {
        case "WAITING":
            return "Очікуємо документи"
        case "PAYED":
            return "Виплачено"
        case "REFUSAL":
            return "Відмова"
        case "PENDING":
            return "На розгляді"
    }
}