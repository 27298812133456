import React from "react";
import Header from "../../header/Header";
import ShortContracts from "./ShortContracts";

export default function Contracts(){
    return (
        <>
            <div className="row mx-2">
                <div className="col-12 p-2 page-header" >
                    <Header showConsultant={false}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-12 p-3 px-5 page-block-min" >
                    <ShortContracts />
                </div>

            </div>
        </>
    )
}