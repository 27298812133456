import React, {useEffect, useState} from "react";

export default function CrudNav({getData, page, size}){
    const [pages, setPages] = useState([])
    useEffect(() => {
        let arr = []
        let start = 1;
        let finish = page.totalPages;
        if (finish > 12) {
            if (page.number > 6)
                start = page.number - 6;
            if ((page.number + 5) < page.totalPages)
                finish = page.number + 5;
        }
        for (let i = start; i <= finish; i++) {
            arr.push(i)
        }
        setPages(arr)
    }, [page])
    //region nav functions
    const onFirst = function () {
        getData(0);
    }

    const onLast = function () {
        getData(page.totalPages - 1)
    }

    const onNext = function () {

        getData(page.number + 1)
    }

    const onPrev = function () {
        getData(page.number - 1)
    }

    const onPage = function (page) {
        getData(page)
    }
    useEffect(() => {
        getData(0)
    }, [size])
    //endregion

    return (<>
        <nav aria-label="table navigation">
            <div className={"float-md-start text-black-50"}>
                Всього сторінок: {page.totalPages} <br/>Всього записів: {page.totalElements}
            </div>
            <ul className="pagination justify-content-end">
                <li className={page.number === 0 ? "page-item disabled" : "page-item"}>
                    <button className="page-link" title="Перша"
                            onClick={() => onFirst()}>&laquo;</button>
                </li>
                <li className={page.number === 0 ? "page-item disabled" : "page-item"}>
                    <button className="page-link" onClick={() => onPrev()}>Поп.</button>
                </li>
                {pages.map((p) => (
                    <li className={"page-item" + ((p === page.number + 1) ? " active" : "")} key={p}>
                        <button className="page-link" onClick={() => onPage(p - 1)}>{p}</button>
                    </li>))}
                <li className={page.number === (page.totalPages - 1) ? "page-item disabled" : "page-item"}>
                    <button className="page-link" onClick={() => onNext()}>Наст.</button>
                </li>
                <li className={page.number === (page.totalPages - 1) ? "page-item disabled" : "page-item"}>
                    <button className="page-link" title="Остання"
                            onClick={() => onLast()}>&raquo;</button>
                </li>
            </ul>
        </nav>
    </>)
}