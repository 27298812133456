import React, {useState} from "react";
import Header from "../../header/Header";
import Total from "./Total";
import Year from "./Year";
import "./schedule.scss";

export default function Schedule() {
const [typeCal, setTypeCal] = useState("year")

    return (
        <>
            <div className="row mx-2">
                <div className="col-12 p-2 page-header">
                    <Header showConsultant={false}/>
                </div>
            </div>
            <div className="row g-0 mx-2 my-2">
                <div className="col-12 p-3  page-block-min">
                    <div className="my-3">
                        <div className="px-4"> {/*this px move from page-block-min*/}
                            <h4 className={"text-black-50 text-uppercase mb-3"}>Календар платежів</h4>
                            <button className={"btn " + (typeCal === "year" ? "btn-success" : "btn-outline-secondary")} onClick={()=>setTypeCal("year")}>Поточний рік</button>
                            <button className={"btn mx-2 " + (typeCal === "total" ? "btn-success" : "btn-outline-secondary")} onClick={()=>setTypeCal("total")}>Всі роки</button>
                        </div>
                        {typeCal === "total" && <Total />}
                        {typeCal === "year" && <Year />}
                    </div>
                </div>

            </div>
        </>
    )
}