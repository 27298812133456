import React, {useEffect, useRef, useState} from "react";
import "./InsuredEvent.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Modal} from 'bootstrap';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import Alert from "../../common/Alert";
import WrapperFetch from "../../../srv_tools/WrapperFetch";

export default function InsuredEvent({btnText = "Страховий випадок", btnAddClass = "rounded-pill"}) {
    const [user, setUser] = useState({"clName": "", "person": {"email": "", "phoneMob": ""}})
    useEffect(() => {
        if (window.localStorage['user'] && window.localStorage['user'].length > 2)
            setUser(JSON.parse(window.localStorage['user']));
    }, [])

    const [contracts, setContracts] = useState([])

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [insuredName, setInsuredName] = useState("")
    const [eventDate, setEventDate] = useState("")
    const [message, setMessage] = useState("")
    const [contractList, setContractList] = useState([])

    const [errorMsg, setErrorMsg] = useState("")

    const animatedComponents = makeAnimated();

    useEffect(() => {
        WrapperFetch.getInstance().enqueue("/api/v1/contracts/short", {redirect: "error"},
            l => {
                l = (l._embedded && l._embedded.shortContracts) ? l._embedded.shortContracts : []
                l = l.filter(c => !c.isClose)
                l = l.map((c) => {
                    return {value: c.id, label: c.printId + " " + c.programName}
                })
                setContractList(l)
            },
            e => setErrorMsg(e))
    }, [])

    const onChangeContracts = (newValue) =>{
        setContracts(newValue.map(c=>c.value))
    }

    useEffect(() => {
        setName(user.clName)
        setInsuredName(user.clName)
        setEmail(user.person.email)
        setPhone(user.person.phoneMob)
    }, [user])

    const valid = () => {
        if (name.length < 2 || name.split(' ').length < 3) return false
        if (email.length === 0 || email.split('@').length !== 2) return false
        if (!phone.match(/^\+\d{12}$/i)) return false
        if (insuredName.length < 2 || insuredName.split(' ').length < 3) return false
        if (message.length < 5) return false
        if (contracts.length === 0) return false
        return true
    }

    const [responseError, setResponseError] = useState({text: "", title: ""})


    const send = () => {
        const req = {
            name, email, phone, insuredName, eventDate, message
        }
        req['contracts'] = contracts
        Modal.getInstance(document.getElementById('insured_event')).hide()
        fetch('/api/v1/insuredEvents', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
            .catch(reason => console.log("error:", reason))
            .then(r => {
                if (r.status === 201) {
                    //ok
                    let divModalOk = document.getElementById('insured_event_ok')
                    let modalOk = new Modal(divModalOk);
                    divModalOk.addEventListener('hide.bs.modal', function (event) {
                        window.location.replace('/insurance-payment')
                    })
                    modalOk.show()
                    return
                }
                if (r.status === 500) {
                    return r.json()
                }
                return r.json()

            })
            // .catch(reason => console.log("error2:", reason))
            .then(error => {
                if (!error) return
                new Modal(document.getElementById('insured_event_response_error')).show()
                setResponseError({text: error.message, title: error.error})
            })
    }
    return (
        <>
            <button className={"btn btn-danger text-uppercase my-2 " + btnAddClass} data-bs-toggle="modal"
                    onClick={() => {

                    }}
                    data-bs-target="#insured_event">{btnText}
            </button>
            <div className="modal fade insured_event" id="insured_event" tabIndex="-1"
                 aria-labelledby="insured_eventModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content ">
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title fw-bold" id="insured_eventModalLabel">Повідомити про страховий
                                випадок</h5>
                            {/*<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                        </div>
                        <div className="modal-body d-flex justify-content-center">
                            <div className={"w-75 "}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="event_form_name"
                                           placeholder="Ваше ім'я" value={name}
                                           onChange={event => setName(event.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="event_form_email"
                                           placeholder="Email" value={email}
                                           onChange={event => setEmail(event.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="event_form_phone"
                                           placeholder="+38099111-22-33" value={phone}
                                           onChange={event => setPhone(event.target.value)}/>
                                </div>
                                <div className="row mb-3">
                                    {errorMsg &&
                                        <Alert header={"Помилка отримання даних!"} type={"danger"}>
                                            {errorMsg}
                                        </Alert>
                                    }
                                    <Select isMulti options={contractList}
                                            components={animatedComponents}
                                            closeMenuOnSelect={false}
                                            styles={{
                                                menuList: () => ({
                                                    color: 'black'
                                                })
                                            }}
                                            onChange={onChangeContracts}
                                    />
                                </div>

                                <div className="mb-3">
                                    <input type="text" className="form-control" id="event_form_fio_issued"
                                           placeholder="ПІБ Застрахованної особи"
                                           onChange={event => setInsuredName(event.target.value)}
                                           value={insuredName}
                                    />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="event_form_date"
                                           placeholder="Дата події"
                                           value={eventDate}
                                           onChange={event => setEventDate(event.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control" id="event_form_message"
                                              value={message}
                                              onChange={event => setMessage(event.target.value)}
                                              placeholder="Повідомленя (зазначте, будь ласка, що саме трапилось, де та за яких обставин)"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                            <button type="button" className="btn btn-danger"
                                    onClick={send} disabled={!valid()}>Повідомити
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="insured_event_response_error" className="modal fade" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{responseError.title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>{responseError.text}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="insured_event_ok" className="modal fade" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Випадок зафіксованно!</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <p>Будь ласка, ознайомтесь, з переліком документів, які необхідно надіслати в страхову
                                компанію:</p>
                            <p><a href="https://gli.com.ua/diyi-pry-strahovomu-vypadku/#finances" target="_blank"
                                  rel="noreferrer">Документи</a></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function InputContract({setter, contracts, value, index}) {
    const inputContract = useRef(null)
    useEffect(() => {
        if (index === contracts.length - 1) {
            setTimeout(() => {
                inputContract.current.focus()
            }, 100)
        }
    }, [])
    return (
        <div className="row mb-3">
            <div className="col-md-10">
                <input type="text" className="form-control" placeholder="Номер договору"
                       onChange={e => {
                           if (!e.target.value.match(/^\d{0,10}$/i)) return
                           contracts[index].value = e.target.value
                           setter(contracts.slice())
                       }}
                       value={value}
                       ref={inputContract}
                />
            </div>
            <div className="col-md-2">
                <button className="btn btn-light"
                        title="Видалити цей договір с запросу"
                        onClick={() => {
                            setter(contracts.filter(function (item, i) {
                                return i !== index
                            }));
                        }}>
                    <FontAwesomeIcon
                        icon={solid("minus")}
                        size={"1x"}/></button>
            </div>
        </div>
    )
}